import Table from "react-bootstrap/Table";
import ArrowUpDown from "../icons/ArrowUpDown";
import {
  flexRender,
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  OnChangeFn,
} from "@tanstack/react-table";
import DateLocale from "../DateLocale";
import PrintAction from "./PrintAction";

type Props = {
  data: Product[];
  sorting: SortingState;
  onSorting: OnChangeFn<SortingState>;
  onPrint: (id: number) => void;
};

type Product = {
  name: string;
  customer_name: string;
  weight: number;
  batch_code: string;
  created_at: string;
  expires_at: string;
  product_sheet_id: null | number;
};
const columnHelper = createColumnHelper<Product>();

function getColumns(onPrint: (id: number) => void) {
  return [
    columnHelper.accessor("created_at", {
      header: "תאריך ייצור",
      cell: (props: any) => <DateLocale utcDate={props.getValue()} />,
    }),

    columnHelper.accessor("name", {
      header: "מוצר",
      cell: (props: any) => <>{props.getValue()}</>,
    }),
    columnHelper.accessor("customer_name", {
      header: `לקוח`,
      cell: (props: any) => <>{props.getValue()}</>,
    }),
    columnHelper.accessor("weight", {
      header: `משקל`,
      cell: (props: any) => <>{props.getValue()}</>,
    }),
    columnHelper.accessor("expires_at", {
      header: "תאריך תפוגה",
      cell: (props: any) => <DateLocale utcDate={props.getValue()} />,
    }),
    columnHelper.accessor("batch_code", {
      header: "מס אצווה",
      enableSorting: false,
      cell: (props: any) => <>{props.getValue()}</>,
    }),
    columnHelper.accessor("product_sheet_id", {
      header: "דף ליקוט",
      enableSorting: false,
      cell: (props: any) => (
        <>
          {props.getValue() === null ? null : (
            <PrintAction onClick={() => onPrint(props.getValue())} />
          )}
        </>
      ),
    }),
  ];
}

const ProductLogTable = ({ data, sorting, onSorting, onPrint }: Props) => {
  const columns = getColumns(onPrint);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: onSorting,
    state: { sorting },
  });

  return (
    <Table size="sm">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder ? null : <Header header={header} />}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const Header = ({ header }: { header: any }) => {
  return (
    <>
      {flexRender(header.column.columnDef.header, header.getContext())}
      {header.column.getCanSort() && (
        <div
          className="mx-2 d-inline-block Sorter"
          onClick={header.column.getToggleSortingHandler()}
        >
          <ArrowUpDown />
        </div>
      )}
    </>
  );
};

export default ProductLogTable;
