import Container from "react-bootstrap/Container";
import RawMaterial from "./RawMaterial";

//Rawmaterial not assigned yet to a batch
const RawMaterialCollection = ({
  available,
  handleRemove,
  provided,
  snapshot,
}) => {
  return (
    <Container
      ref={provided.innerRef}
      {...provided.droppableProps}
      style={{
        backgroundColor: snapshot.isDraggingOver ? "var(--bs-gray-300)" : "",
      }}
    >
      {available.map((raw, index) => {
        return (
          <RawMaterial
            key={raw.id}
            raw={raw}
            index={index}
            handleRemove={() => handleRemove(raw.id)}
          />
        );
      })}
      {provided.placeholder}
    </Container>
  );
};

export default RawMaterialCollection;
