import Batch from "./Batch";

//Get an array of batches w/ the raw materials
const BatchList = ({ data }) => {
  let list = data.batches_order.map((batchId) => {
    const batch = data.batches[batchId];
    const raw_materials = batch.raw_material_ids.map(
      (id) => data.raw_materials_collection[id]
    );
    return <Batch key={batch.id} batch={batch} rawmaterials={raw_materials} />;
  });
  return list;
};

export default BatchList;
