import { Container } from "react-bootstrap";
import CustomerSelect from "../customer/CustomerSelect";
import ActionBarBase from "../ActionBar";
import NewEntity from "../form/NewEntity";

type Props = {
  onNew: (customerId: string) => void;
  setCustomerID: (e: any) => void;
  customer_id: string;
};

const ActionBar = ({ onNew, customer_id, setCustomerID }: Props) => {
  return (
    <Container
      className={["d-flex", "p-0", "justify-content-between"].join(" ")}
    >
      <ActionBarBase>
        לקוח:
        <CustomerSelect
          onChange={(e) => {
            const t = e.target;
            setCustomerID({
              value: t.value,
              name: t.options[t.options.selectedIndex].innerHTML,
            });
          }}
          value={customer_id}
        />
      </ActionBarBase>
      <ActionBarBase>
        <NewEntity onNew={() => onNew(customer_id)} />
      </ActionBarBase>
    </Container>
  );
};

export default ActionBar;
