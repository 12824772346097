import RawPartPartBadge from "./RawPartPartBadge";

type Props = {
  parts: any[];
};

const RawPartPartsCell = ({ parts }: Props) => {
  return (
    <>
      {parts.map((p, index) => (
        <RawPartPartBadge key={index} code={p.name} />
      ))}
    </>
  );
};

export default RawPartPartsCell;
