import { useEffect, useRef } from "react";
import SubmitButton from "../form/SubmitButton";
import { default as BootForm } from "react-bootstrap/Form";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Status from "../form/Status";

interface Values {
  email: string;
  password: string;
}

type props = {
  handleSubmit: (values: Values, control: any) => void;
};

const LoginForm = ({ handleSubmit }: props) => {
  const emailField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (emailField.current != null) {
      emailField.current.focus();
    }
  }, []);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={partSchema}
      validateOnBlur={false}
    >
      {({ isSubmitting, status }) => (
        // @ts-ignore
        <Form as={BootForm}>
          {status && (
            <Status message={status.message} variant={status.variant} />
          )}
          <BootForm.Group>
            <BootForm.Label htmlFor="email">אימייל</BootForm.Label>
            <Field
              name="email"
              innerRef={emailField}
              className="form-control"
              type="text"
            />
            <ErrorMessage
              name="email"
              className="text-danger"
              component="div"
            />
          </BootForm.Group>

          <BootForm.Group>
            <BootForm.Label htmlFor="password">סיסמה</BootForm.Label>
            <Field name="password" className="form-control" type="password" />
            <ErrorMessage
              name="password"
              className="text-danger"
              component="div"
            />
          </BootForm.Group>

          <BootForm.Group className="mt-2">
            <SubmitButton label="התחברות" isSubmitting={isSubmitting} />
          </BootForm.Group>
        </Form>
      )}
    </Formik>
  );
};

const partSchema = () => {
  return Yup.object({
    email: Yup.string().email().trim().required("*"),
    password: Yup.string().trim().required("*"),
  });
};
export default LoginForm;
