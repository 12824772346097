import { useEffect, useRef } from "react";
import SubmitCancel from "../form/SubmitCancel";
import { default as BootForm } from "react-bootstrap/Form";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ActiveNonActiveSelect from "../form/ActiveNoActiveSelect";
import Status from "../form/Status";

const CreateForm = ({ handleSubmit, handleCancel }) => {
  const nameField = useRef();

  useEffect(() => {
    nameField.current.focus();
  }, []);

  return (
    <Formik
      initialValues={{
        name: "",
        expires_in: 0,
        active: "true",
      }}
      onSubmit={handleSubmit}
      validationSchema={partSchema}
      validateOnBlur={false}
    >
      {({ isSubmitting, status }) => (
        <Form as={BootForm}>
          {status && (
            <Status message={status.message} variant={status.variant} />
          )}
          <BootForm.Group>
            <BootForm.Label htmlFor="name">שם</BootForm.Label>
            <Field
              name="name"
              innerRef={nameField}
              className="form-control"
              type="text"
            />
            <ErrorMessage name="name" className="text-danger" component="div" />
          </BootForm.Group>

          <BootForm.Group>
            <BootForm.Label htmlFor="expires_in"> תפוגה (ימים)</BootForm.Label>
            <Field name="expires_in" className="form-control" type="text" />
            <ErrorMessage
              name="expires_in"
              className="text-danger"
              component="div"
            />
          </BootForm.Group>

          <BootForm.Group>
            <BootForm.Label htmlFor="active">פעִיל</BootForm.Label>
            <ActiveNonActiveSelect name="active" />
          </BootForm.Group>

          <BootForm.Group>
            <BootForm.Label></BootForm.Label>
            <SubmitCancel
              handleCancel={handleCancel}
              isSubmitting={isSubmitting}
            />
          </BootForm.Group>
        </Form>
      )}
    </Formik>
  );
};

const partSchema = () => {
  return Yup.object({
    name: Yup.string().trim().max(64).required("*"),
    expires_in: Yup.number().min(1).required("*"),
  });
};
export default CreateForm;
