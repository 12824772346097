import Stack from "react-bootstrap/Stack";

const ActionBar = ({ gap = 2, dir = "ltr", children }) => {
  const clzz = dir === "ltr" ? "justify-content-end" : "justify-content-start";
  return (
    <Stack direction="horizontal" className={clzz} gap={gap}>
      {children}
    </Stack>
  );
};

export default ActionBar;
