import Body from "../components/Body";
import BatchBuilder from "../components/BatchBuilder";

const GoodsPage = () => {
  return (
    <Body>
      <h2>קבלת סחורה</h2>
      <BatchBuilder />
    </Body>
  );
};

export default GoodsPage;
