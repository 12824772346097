import Button from "react-bootstrap/Button";

type Props = {
  onReset: () => void;
  disabled?: boolean;
};

const ResetButton = ({ onReset, disabled = false }: Props) => {
  return (
    <Button onClick={onReset} disabled={disabled} variant="outline-secondary">
      איפוס
    </Button>
  );
};

export default ResetButton;
