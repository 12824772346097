import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { Draggable } from "react-beautiful-dnd";
import Delete from "../icons/Delete";
import Number from "../i18n/Number.tsx";
import DateLocale from "../DateLocale";

const RawMaterial = ({ raw, index, handleRemove }) => {
  return (
    <Draggable draggableId={raw.id} index={index}>
      {(provided) => (
        <Card
          className="RawMaterial"
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Card.Header>
            {handleRemove && <DeleteRawMaterial handleRemove={handleRemove} />}
            {raw.supplierName} &#x25CF; {raw.partName} &#x25CF; {raw.kindName}
          </Card.Header>
          <ListGroup className="list-group-flush">
            <ListGroup.Item>
              יום שחיטה: <DateLocale utcDate={raw.slaughteredAt} /> &#x25CF; מס
              שחיטה: {raw.slaughterId}
            </ListGroup.Item>
            <ListGroup.Item>
              משקל: {raw.weight} &#x25CF; טמפרטורה:{" "}
              <Number value={raw.temperature} />
            </ListGroup.Item>
            {raw.comment !== "" && (
              <ListGroup.Item>הערה: {raw.comment}</ListGroup.Item>
            )}
          </ListGroup>
        </Card>
      )}
    </Draggable>
  );
};

const DeleteRawMaterial = ({ handleRemove }) => {
  return (
    <Button
      style={{ marginLeft: "6px" }}
      variant="secondary"
      className="btn-sm"
      onClick={handleRemove}
    >
      <Delete />
    </Button>
  );
};
export default RawMaterial;
