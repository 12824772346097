import React from "react";
import Badge from "react-bootstrap/Badge";

const Title = ({ title }) => {
  return (
    <h4>
      <Badge bg="info">{title}</Badge>
    </h4>
  );
};

export default Title;
