import { Button, ButtonGroup } from "react-bootstrap";
import Sticky from "../../icons/Sticky";

type Props = {
  onPrintLabel: () => void;
};

const Actions = ({ onPrintLabel }: Props) => {
  return (
    <ButtonGroup aria-label="batch">
      <Button onClick={onPrintLabel} variant="outline-secondary">
        <Sticky />
      </Button>
    </ButtonGroup>
  );
};

export default Actions;
