import Button from "react-bootstrap/Button";

type props = {
  handleCancel: () => void;
  isSubmitting: boolean;
};

const CancelButton = ({ handleCancel, isSubmitting }: props) => {
  return (
    <Button variant="secondary" onClick={handleCancel} disabled={isSubmitting}>
      ביטול
    </Button>
  );
};

export default CancelButton;
