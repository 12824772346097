import SubmitCancel from "../form/SubmitCancel";
import { default as BootForm } from "react-bootstrap/Form";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Status from "../form/Status";
import CustomerSelect from "./CustomerSelect";

const CreateForm = ({ handleSubmit, handleCancel }) => {
  return (
    <Formik
      initialValues={{
        customer_id: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={partSchema}
      validateOnBlur={false}
    >
      {({ isSubmitting, status }) => (
        <Form as={BootForm}>
          {status && (
            <Status message={status.message} variant={status.variant} />
          )}
          <BootForm.Group>
            <BootForm.Label htmlFor="customer_id">לקוח</BootForm.Label>
            <CustomerSelect name="customer_id" />
            <ErrorMessage
              name="customer_id"
              className="text-danger"
              component="div"
            />
          </BootForm.Group>

          <BootForm.Group>
            <BootForm.Label></BootForm.Label>
            <SubmitCancel
              handleCancel={handleCancel}
              isSubmitting={isSubmitting}
            />
          </BootForm.Group>
        </Form>
      )}
    </Formik>
  );
};

const partSchema = () => {
  return Yup.object({
    customer_id: Yup.string().notempty().required("*"),
  });
};
export default CreateForm;
