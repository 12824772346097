import Check from "../icons/Check";
import XCircle from "../icons/XCircle";
import EditEntity from "../form/EditEntity";

const PartRow = ({ part, handleEdit }) => {
  return (
    <tr>
      <td>{part.name}</td>
      <td>{part.expires_in}</td>
      <td>{part.active ? <Check /> : <XCircle />}</td>
      <td style={{ textAlign: "left" }}>
        <EditEntity onEdit={() => handleEdit(part)} />
      </td>
    </tr>
  );
};

export default PartRow;
