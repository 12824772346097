import Container from "react-bootstrap/Container";
import Body from "../components/Body";
import Login from "../components/Login";

const LoginPage = () => {
  return (
    <Body sidebar={false}>
      <Container className="mx-auto" style={{ width: "600px" }}>
        <h2>כניסה לחשבון</h2>
        <Login />
        <div dir="ltr" className="text-secondary" style={{ fontSize: "10px" }}>
          {process.env.REACT_APP_BUILD_DATE}
        </div>
        .
      </Container>
    </Body>
  );
};

export default LoginPage;
