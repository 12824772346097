import Body from "../components/Body";
import ButcherBatchSelector from "../components/ButcherBatchSelector";

const ButcherPage = () => {
  return (
    <Body>
      <h2>פירוק אצוות</h2>
      <ButcherBatchSelector />
    </Body>
  );
};

export default ButcherPage;
