import { addMethod, string, number } from "yup";

const init = () => {
  addMethod(string, "digitsonly", function () {
    return this.matches(/^\d+$/, "ספרות בלבד");
  });

  addMethod(string, "notempty", function () {
    return this.matches(/^.+$/, "שדה ריק");
  });

  addMethod(number, "temperature", function () {
    return this.test(
      "temp",
      "מקסימום 2 ספרות אחרי הנקודה העשרונית",
      function (value) {
        return /^-?\d+(\.\d{1,2})?$/.test(value);
      }
    );
  });

  addMethod(number, "weight", function () {
    return this.test(
      "weight",
      "מקסימום 2 ספרות אחרי הנקודה העשרונית",
      function (value) {
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    );
  });
};

export default init;
