import Table from "react-bootstrap/Table";
import BatchRow from "./BatchRow";
import { confirm } from "../form/ConfirmDialog";
import { useDeleteBatchMutation } from "./hooks";
import { information } from "../form/InformationDialog";

const BatchesTable = ({ batches }) => {
  const deleteBatch = useDeleteBatchMutation({
    onError: () => {
      information("אירעה שגיאה");
    },
  });

  const handleDelete = async (batch) => {
    if (
      !(await confirm(
        `מחיקת אצווה '${batch.code}'. 
         (אם האצווה בפירוק, מחיקת האצווה תמחק גם מידע זה.)`
      ))
    ) {
      return;
    }

    deleteBatch.mutate(batch.id);
  };

  return (
    <Table hover={true}>
      <thead>
        <tr>
          <th>אצווה</th>
          <th>תאריך קבלה</th>
          <th>התקבל על ידי</th>
          <th>מספר חומרי הגלם</th>
          <th>משקל כולל</th>
          <th>תאריך פרוק</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {batches.map((batch) => {
          return (
            <BatchRow key={batch.id} batch={batch} onDelete={handleDelete} />
          );
        })}
      </tbody>
    </Table>
  );
};

export default BatchesTable;
