import React from "react";
import Modal from "react-bootstrap/Modal";

/*
 * modal size: sm,lg,xl
 */
type Props = {
  show: boolean;
  title: string;
  modalSize?: "sm" | "lg" | "xl";
  className?: string;
  children: React.JSX.Element;
  footer?: React.JSX.Element;
};

function ModalContainer({
  show,
  title,
  modalSize,
  className = "",
  footer,
  children,
}: Props) {
  const size = modalSize === undefined ? "" : "modal-" + modalSize;
  return (
    <Modal show={show} className={`${size} ${className}`}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
}

export default ModalContainer;
