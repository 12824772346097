import React, { useEffect, useRef } from "react";
import SubmitCancel from "../form/SubmitCancel";
import { default as BootForm } from "react-bootstrap/Form";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import RoleSelect from "./RoleSelect";
import Status from "../form/Status";

const UpdateForm = ({ initialValues, handleSubmit, handleCancel }) => {
  const firstField = useRef();

  useEffect(() => {
    firstField.current.focus();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={userSchema}
      validateOnBlur={false}
    >
      {({ isSubmitting, status }) => (
        <Form as={BootForm}>
          {status && (
            <Status message={status.message} variant={status.variant} />
          )}
          <BootForm.Group>
            <BootForm.Label htmlFor="first">שם פרטי</BootForm.Label>
            <Field
              name="first"
              innerRef={firstField}
              className="form-control"
              type="text"
            />
            <ErrorMessage
              name="first"
              className="text-danger"
              component="div"
            />
          </BootForm.Group>
          <BootForm.Group>
            <BootForm.Label htmlFor="last">שם משפחה</BootForm.Label>
            <Field name="last" className="form-control" type="text" />
            <ErrorMessage name="last" className="text-danger" component="div" />
          </BootForm.Group>

          <BootForm.Group>
            <BootForm.Label htmlFor="role_id">תַפְקִיד</BootForm.Label>
            <RoleSelect name="role_id" />
          </BootForm.Group>

          <BootForm.Group>
            <BootForm.Label></BootForm.Label>
            <SubmitCancel
              handleCancel={handleCancel}
              isSubmitting={isSubmitting}
            />
          </BootForm.Group>
        </Form>
      )}
    </Formik>
  );
};

const userSchema = () => {
  return Yup.object({
    first: Yup.string().trim().min(2).required("*"),
    last: Yup.string().trim().min(2).required("*"),
    role_id: Yup.string().oneOf(["1", "2"]),
  });
};
export default UpdateForm;
