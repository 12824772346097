import { useQuery } from "react-query";
import { fetchSupplier } from "../../supplier/queries";
import DisplayAtom from "../../DisplayAtom";

type Props = {
  id: number;
};

const SupplierCell = ({ id }: Props) => {
  //for listing parts
  const { isLoading, isError, isSuccess, data } = useQuery(
    ["suppliers", id],

    () => fetchSupplier(id)
  );

  return (
    <DisplayAtom
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      atom={data?.body?.name}
    />
  );
};

export default SupplierCell;
