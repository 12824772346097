import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Spinner } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import UsersTable from "./user/UsersTable";
import NewEntity from "./form/NewEntity";
import Vertical from "./layout/Vertical";
import ActionBar from "./ActionBar";
import CreateForm from "./user/CreateForm";
import UpdateForm from "./user/UpdateForm";
import ModalContainer from "./layout/ModalContainer";
import { confirm } from "./form/ConfirmDialog";
import { information } from "./form/InformationDialog";
import { fetchUsers, createUser, deleteUser, updateUser } from "./user/queries";
import useCRUDDialogs from "./crud/hooks";

const Users = () => {
  const [listDeletedUser, setListDeletedUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  //for refetching users and updating the table after delete/create/update
  const queryClient = useQueryClient();
  const {
    setShowCreate,
    isCreateShowing,
    setShowUpdate,
    isUpdateShowing,
    setClose,
  } = useCRUDDialogs();

  const closeDialog = () => {
    setClose();
  };

  //for creating a user
  const mutation = useMutation({
    mutationFn: (variables) => createUser(variables.newUser),
    onSuccess: (_data, variables) => {
      variables.closeDialog();
      queryClient.invalidateQueries({ queryKey: ["users", listDeletedUser] });
    },
    onError: (_err, variables) => {
      variables.setSubmitting(false);
      variables.setStatus({
        message: "העובד לא נשמר, אירעה שגיאה",
        variant: "danger",
      });
    },
  });

  const handleCreate = async (
    { first, last, email, password, role_id },
    { setSubmitting, setStatus }
  ) => {
    let newUser = { first, last, email, password, role_id };
    newUser.role_id = parseInt(newUser.role_id);

    //pass user and callbacks for error/success handling
    let variables = { newUser, closeDialog, setSubmitting, setStatus };
    mutation.mutate(variables);
  };

  //for updating a user
  const updateMutation = useMutation({
    mutationFn: (variables) => updateUser(variables.user.id, variables.user),
    onSuccess: (_data, variables) => {
      variables.closeDialog();
      queryClient.invalidateQueries({ queryKey: ["users", listDeletedUser] });
    },
    onError: (_err, variables) => {
      variables.setSubmitting(false);
      variables.setStatus({
        message: "העובד לא נשמר, אירעה שגיאה",
        variant: "danger",
      });
    },
  });

  const handleUpdate = async (
    { first, last, role_id },
    { setSubmitting, setStatus }
  ) => {
    let id = selectedUser.id;
    let user = { id, first, last, role_id };
    user.role_id = parseInt(user.role_id);

    //pass user and callbacks for error/success handling
    let variables = { user, closeDialog, setSubmitting, setStatus };
    updateMutation.mutate(variables);
  };

  const openUpdateDialog = (user) => {
    setSelectedUser(user);
    setShowUpdate();
  };

  //for deleting a user
  const deleteMutation = useMutation({
    mutationFn: (userId) => deleteUser(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users", listDeletedUser] });
    },
    onError: () => {
      information("העובד לא נמחק, אירעה שגיאה");
    },
  });

  const handleDelete = async (user) => {
    if (!(await confirm(`אנא אשר את מחיקת העובד: ${user.first}`))) {
      return;
    }

    deleteMutation.mutate(user.id);
  };

  //for listing users
  const { isLoading, isError, isSuccess, data } = useQuery(
    ["users", listDeletedUser],

    () => fetchUsers(listDeletedUser)
  );

  let content = null;
  if (isLoading) {
    content = <Spinner animation="border" />;
  }

  if (isError) {
    content = <p>לא ניתן להוריד את רשימת העובדים</p>;
  }

  if (isSuccess) {
    content = (
      <UsersTable
        users={data.body}
        handleEdit={openUpdateDialog}
        handleDelete={handleDelete}
      />
    );
  }

  return (
    <Vertical>
      <ActionBar>
        <Form.Check
          type="checkbox"
          label="הצג לא פעילים"
          value={listDeletedUser}
          onClick={() => setListDeletedUser((prevVal) => !prevVal)}
        />
        <NewEntity onNew={setShowCreate} />
      </ActionBar>
      <ModalContainer show={isCreateShowing()} title="הוספת עובד">
        <CreateForm handleSubmit={handleCreate} handleCancel={setClose} />
      </ModalContainer>
      <ModalContainer show={isUpdateShowing()} title="עריכה">
        <UpdateForm
          initialValues={selectedUser}
          handleSubmit={handleUpdate}
          handleCancel={setClose}
        />
      </ModalContainer>
      {content}
    </Vertical>
  );
};

export default Users;
