import { useRef } from "react";
import PrintModalComponent from "../../layout/PrintModalComponent";
import { usePrintLabel } from "../hooks";
import StickyLabel from "../StickyLabel";
import { RawMaterial } from "./types";

type Props = {
  raw: RawMaterial;
  cancel: () => void;
  batch_code: string;
};

const PrintLabel = ({ cancel, batch_code, raw }: Props) => {
  const componentRef = useRef(null);
  const printLabel = usePrintLabel({
    content: () => componentRef.current,
    onAfterPrint: () => cancel(),
  });

  return (
    <PrintModalComponent
      show={true}
      handlePrint={printLabel}
      handleCancel={cancel}
    >
      <div ref={componentRef}>
        <StickyLabel
          barcodeValue={batch_code}
          barcodeID={`id${batch_code}`}
          {...raw}
        />
      </div>
    </PrintModalComponent>
  );
};

export default PrintLabel;
