import Accordion from "react-bootstrap/Accordion";
import Part from "./Part";
import RawPartPanelHeader, { PartType } from "./RawPartPanelHeader";

export type NewPartType = {
  raw_id: any;
  part: PartType;
};

export type Props = {
  onRemovePart: (ids: { raw_id: any; part_id: any }) => void;
  onAddPart: (np: NewPartType) => void;
  id: any;
  meta: any;
  parts: any[];
};

const RawPartPanel = ({ id, meta, parts, onRemovePart, onAddPart }: Props) => {
  return (
    <Accordion className="px-0">
      <Accordion.Item eventKey="0">
        <RawPartPanelHeader
          {...meta}
          onAddPart={(part) => onAddPart({ raw_id: id, part: part })}
        />
        <Accordion.Header>חלקים:</Accordion.Header>
        <Accordion.Body>
          {parts.map((p) => (
            <Part
              key={p.id}
              part={{ ...p.data, id: p.id }}
              onDeletePart={() => onRemovePart({ raw_id: id, part_id: p.id })}
            />
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default RawPartPanel;
