import { useState } from "react";
import { Stack } from "react-bootstrap";
import AvailableHeader from "./AvailableHeader";
import AvailableProductSelector from "./AvailableProductSelector";

type Props = { customer_id: number; sheet_id: number };

const AvailableProductPanel = ({ sheet_id, customer_id }: Props) => {
  const [customerId, setCustomer] = useState<string>("" + customer_id);
  return (
    <div>
      <h5 className="p-1">בחר מוצרים לדף:</h5>
      <Stack gap={2}>
        <AvailableHeader
          customer_id={`${customerId}`}
          setCustomerID={(selected) => setCustomer(selected.value)}
        />
        <AvailableProductSelector
          customer_id={customerId}
          sheet_id={sheet_id}
          key={customerId}
        />
      </Stack>
    </div>
  );
};

export default AvailableProductPanel;
