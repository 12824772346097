import { useEffect, useRef } from "react";
import SubmitCancel from "../form/SubmitCancel";
import { default as BootForm } from "react-bootstrap/Form";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ActiveNonActiveSelect from "../form/ActiveNoActiveSelect";
import Status from "../form/Status";
import RawPartPartSelect from "./RawPartPartSelect";
import { usePartLoader } from "./hook";

const CreateRawPartForm = ({ handleSubmit, handleCancel }) => {
  const nameField = useRef(undefined);

  useEffect(() => {
    nameField.current?.focus();
  }, []);

  const { isLoading, isError, isSuccess, data } = usePartLoader();
  if (isLoading || isError) {
    return <>...</>;
  }

  let partsData = null;
  if (isSuccess) {
    partsData = data.body;
  }

  return (
    <Formik
      initialValues={{
        name: "",
        active: "true",
        strict_weight: "true",
        parts: [],
      }}
      onSubmit={handleSubmit}
      validationSchema={partSchema}
      validateOnBlur={false}
    >
      {({ isSubmitting, status }) => (
        <Form as={BootForm}>
          {status && (
            <Status message={status.message} variant={status.variant} />
          )}
          <BootForm.Group>
            <BootForm.Label htmlFor="name">שם</BootForm.Label>
            <Field
              name="name"
              innerRef={nameField}
              className="form-control"
              type="text"
            />
            <ErrorMessage name="name" className="text-danger" component="div" />
          </BootForm.Group>
          <BootForm.Group>
            <BootForm.Label htmlFor="parts">חלקים</BootForm.Label>
            <RawPartPartSelect name="parts" parts={partsData} />
            <ErrorMessage
              name="parts"
              className="text-danger"
              component="div"
            />
          </BootForm.Group>
          <BootForm.Group>
            <BootForm.Label htmlFor="strict_weight">
              משקל מחמיר בפירוק
            </BootForm.Label>
            <ActiveNonActiveSelect
              name="strict_weight"
              trueValue="כן"
              falseValue="לא"
            />
          </BootForm.Group>

          <BootForm.Group>
            <BootForm.Label htmlFor="active">פעִיל</BootForm.Label>
            <ActiveNonActiveSelect name="active" />
          </BootForm.Group>
          <BootForm.Group>
            <BootForm.Label></BootForm.Label>
            <SubmitCancel
              handleCancel={handleCancel}
              isSubmitting={isSubmitting}
            />
          </BootForm.Group>
        </Form>
      )}
    </Formik>
  );
};

const partSchema = () => {
  return Yup.object({
    name: Yup.string().trim().max(64).required("*"),
    parts: Yup.array().min(1, "*").required("*"),
  });
};
export default CreateRawPartForm;
