import { setLocale } from "yup";

// sets Hebrew for Yup
// https://github.com/jquense/yup/issues/1216
const init = () => {
  setLocale({
    mixed: {
      default: "שגיאה בשדה",
      notType: "שגיאה בשדה",
    },
    string: {
      /* eslint-disable no-template-curly-in-string */
      min: "השדה חייב להכיל לפחות ${min} אותיות",
      max: "השדה חייב להכיל עד ${max} אותיות",
      /* eslint-enable no-template-curly-in-string */
      email: "כתובת אימייל לא חוקית",
    },
    number: {
      positive: "המספר חייב להיות חיובי",
      /* eslint-disable no-template-curly-in-string */
      max: "המספר המקסימלי לשדה הוא ${max} ",
      min: "המספר המינימלי לשדה הוא ${min} ",
      /* eslint-enable no-template-curly-in-string */
    },
  });
};

export default init;
