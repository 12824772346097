import Body from "../components/Body";
import Users from "../components/Users";

const UsersPage = () => {
  return (
    <Body>
      <h2>עובדים</h2>
      <Users />
    </Body>
  );
};

export default UsersPage;
