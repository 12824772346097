import React from "react";
import Check from "../icons/Check";
import XCircle from "../icons/XCircle";
import EditEntity from "../form/EditEntity";
import DeleteEntity from "../form/DeleteEntity";
import DateLocale from "../DateLocale";

const UserRow = ({ user, handleDelete, handleEdit }) => {
  return (
    <tr>
      <td>{user.first}</td>
      <td>{user.last}</td>
      <td>{user.email}</td>
      <td>{user.role_name}</td>
      <td>
        <DateLocale utcDate={user.created_at} />
      </td>
      <td>{user.deleted_at === null ? <Check /> : <XCircle />}</td>
      <td>
        {user.deleted_at === null ? (
          <EditEntity onEdit={() => handleEdit(user)} />
        ) : null}
      </td>
      <td>
        {user.deleted_at === null ? (
          <DeleteEntity onDelete={() => handleDelete(user)} />
        ) : null}
      </td>
    </tr>
  );
};

export default UserRow;
