import React from "react";
import Container from "react-bootstrap/Container";
import RawMaterial from "./RawMaterial";

const BatchRawMaterialList = ({ list, provided, snapshot }) => {
  return (
    <Container
      className="BatchRawMaterialList"
      ref={provided.innerRef}
      {...provided.droppableProps}
      style={{
        backgroundColor: snapshot.isDraggingOver ? "var(--bs-gray-300)" : "",
      }}
    >
      {list.map((raw, index) => {
        return (
          <RawMaterial
            key={raw.id}
            raw={raw}
            index={index}
            handleRemove={null}
          />
        );
      })}
      {provided.placeholder}
    </Container>
  );
};

export default BatchRawMaterialList;
