import JsBarcode from "jsbarcode";
import { useEffect } from "react";

type Props = {
  value: string;
  id: string; //a unique id for the barcode
};

const Barcode = ({ value, id }: Props) => {
  useEffect(() => {
    JsBarcode("#" + id, value);
  }, [value, id]);

  return <img id={id} width="145px" height="97px" alt="loading" />;
};

export default Barcode;
