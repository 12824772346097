import { Button, ButtonToolbar } from "react-bootstrap";
import PrintIcon from "../icons/Print";

type Props = {
  onClick: () => void;
};

const PrintAction = ({ onClick }: Props) => {
  return (
    <ButtonToolbar>
      <Button onClick={onClick} variant="outline-secondary">
        <PrintIcon />
      </Button>
    </ButtonToolbar>
  );
};

export default PrintAction;
