import { useRef } from "react";
import Table from "react-bootstrap/Table";
import { useReactToPrint } from "react-to-print";
import DateLocale from "../DateLocale";
import RawMaterialesList from "./rawmaterial/RawMaterialList";
import RawMaterialTable from "./rawmaterial/RawMaterialTable";
import PrintModalComponent from "../layout/PrintModalComponent";

type Props = {
  show: boolean;
  cancel: () => void;
  batch: any;
};

const PrintBatch = ({ show, cancel, batch }: Props) => {
  const componentRef = useRef(null);
  const printBatch = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => cancel(),
    documentTitle: "",
    pageStyle: `
        body {
           direction: rtl;
        }`,
  });

  return (
    <PrintModalComponent
      show={show}
      handlePrint={printBatch}
      handleCancel={cancel}
    >
      <div ref={componentRef}>
        <h2> אצווה: {batch.code} </h2>
        <Table>
          <thead>
            <tr>
              <th>תאריך קבלה</th>
              <th>התקבל על ידי</th>
              <th>מספר חומרי הגלם</th>
              <th>משקל כולל</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <DateLocale utcDate={batch.created_at} />
              </td>
              <td>
                {batch.entered_by.first} {batch.entered_by.last}
              </td>
              <td>{batch.raw_material_count}</td>
              <td>{batch.raw_material_weight}</td>
            </tr>
          </tbody>
        </Table>
        <br />
        <RawMaterialesList id={batch.id}>
          {(data: any) => (
            <RawMaterialTable
              rawmaterials={data}
              isPrint={true}
              batch_code=""
            />
          )}
        </RawMaterialesList>
      </div>
    </PrintModalComponent>
  );
};

export default PrintBatch;
