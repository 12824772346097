import { Field } from "formik";
import { default as BootForm } from "react-bootstrap/Form";

type Props = {
  name: string;
  parts: any[];
};

const RawPartPartSelect = ({ name, parts }: Props) => {
  return (
    <div className="d-flex flex-wrap justify-content-between">
      {parts.map((p) => (
        <Field
          key={p.id}
          as={SwitchType}
          type="checkbox"
          label={p.name}
          value={`${p.id}`}
          name={name}
          id={`parts_${p.id}`}
        ></Field>
      ))}
    </div>
  );
};

const SwitchType = (props: any) => (
  <BootForm.Check {...props} inline type="switch" />
);

export default RawPartPartSelect;
