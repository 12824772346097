import { fetchBatches } from "../queries";

let nextBatchNumber = 0;

export const resetBatchNumber = () => {
  nextBatchNumber = 0;
};

//We need to synch the nextBatchNumber with what is saved on the server
//'first' indicates that we are starting a 'new' set of batches to  receive goods.
//We sync when first is true
export const batchName = async (first: boolean) => {
  if (first) {
    //pull the last batch from the server
    let batches = await fetchBatches({ page: 1, pageSize: 1 });
    setNextBatchNumber(batches.body.data);
  }

  let name = getBatchPrefix();
  return name + ++nextBatchNumber;
};

function getBatchPrefix(): string {
  let now = new Date().toLocaleDateString("he", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
  });

  return now.replace(/\./g, "");
}

function setNextBatchNumber(batchArray: []) {
  if (batchArray.length === 0) {
    //no batches in the system
    return;
  }
  // @ts-ignore
  const code: string = batchArray[0].code;
  const now: string = getBatchPrefix();
  if (!code.startsWith(now)) {
    return;
  }

  //the batch pulled from the server is from today -
  //set the next batch number
  nextBatchNumber = parseInt(code.substring(now.length));
}
