import { useQueryClient, useQuery, useMutation } from "react-query";
import {
  createProductSheet,
  fetchProductSheetProducts,
  fetchProductSheets,
  fetchProductSheet,
  deleteProductSheet,
  deleteProductSheetProduct,
  updateProductSheetIsFinal,
} from "./queries";

const useProductSheetDeleteMutation = (onError) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (sheetID) => deleteProductSheet(sheetID),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["productsheets"] });
      queryClient.invalidateQueries({ queryKey: ["products"] });
    },
    onError: onError,
  });
};

const useProductSheetIsFinalMutation = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (vars) => updateProductSheetIsFinal(vars.sheetID, vars.isFinal),
    onSuccess: (data, vars) => {
      queryClient.invalidateQueries({ queryKey: ["productsheets"] });
      onSuccess(data, vars);
    },
    onError: onError,
  });
};

const useProductSheetQuery = (sheetID) => {
  return useQuery(["productsheets", sheetID], () => fetchProductSheet(sheetID));
};

const useProductSheetProductsQuery = (sheetID) => {
  return useQuery(["productsheets", sheetID, "products"], () =>
    fetchProductSheetProducts(sheetID)
  );
};

const useProductSheetProductDeleteMutation = (onError) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (vars) =>
      deleteProductSheetProduct(vars.sheetID, vars.productID),
    onSuccess: (_data, vars) => {
      queryClient.invalidateQueries({
        queryKey: ["productsheets", vars.sheetID, "products"],
      });
    },
    onError: onError,
  });
};

const useProductSheetsQuery = (customer_id, pagination, sortBy) => {
  let sort = JSON.stringify(sortBy);
  let p = { ...pagination, sort };
  p = customer_id === "0" ? p : { ...p, customer_id };
  return useQuery(["productsheets", customer_id, sort, pagination], () =>
    fetchProductSheets(p)
  );
};

//for creating a sheet
//
const useProductSheetCreateMutation = (handleSaveError) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables) => createProductSheet(variables.newSheet),
    onSuccess: (_data, variables) => {
      variables.closeDialog();
      queryClient.invalidateQueries({ queryKey: ["productsheets"] });
    },
    onError: handleSaveError,
  });
};

export {
  useProductSheetProductsQuery,
  useProductSheetCreateMutation,
  useProductSheetsQuery,
  useProductSheetQuery,
  useProductSheetDeleteMutation,
  useProductSheetProductDeleteMutation,
  useProductSheetIsFinalMutation,
};
