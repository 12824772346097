import DateLocale from "../DateLocale";
import { Table } from "react-bootstrap";
import Product from "./types";
import ProductTable from "./ProductTable";
import weightFixed from "../../weight";

type Props = {
  customer_name: string;
  created_at: string;
  product_list: Product[];
};

const ProductSheet = ({ customer_name, created_at, product_list }: Props) => {
  let totalWeight = product_list.reduce(
    (weight, prod) => weight + prod.weight,
    0
  );
  return (
    <>
      <h2>לקוח: {customer_name} </h2>
      <Table>
        <thead>
          <tr>
            <th>תאריך ליקוט</th>
            <th>מספר מוצרים</th>
            <th>משקל כולל</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <DateLocale utcDate={created_at} />
            </td>
            <td>{product_list.length}</td>
            <td>{weightFixed(totalWeight)}</td>
          </tr>
        </tbody>
      </Table>
      <br />
      <ProductTable product_list={product_list} />
    </>
  );
};

export default ProductSheet;
