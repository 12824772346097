import { api } from "../../ApiClient";

export const fetchRawPart = async (partID) => {
  return await api.get(`/rawparts/${partID}`);
};

export const fetchRawPartParts = async (partID) => {
  return await api.get(`/rawparts/${partID}/parts`);
};

export const fetchRawParts = async () => {
  return await api.get("/rawparts");
};

export const createRawPart = async (part) => {
  return await api.post("/rawparts", part);
};

export const updateRawPart = async (partID, data) => {
  return await api.put(`/rawparts/${partID}`, data);
};

export const fetchParts = async (params) => {
  return await api.get("/parts", params);
};

export const createPart = async (part) => {
  return await api.post("/parts", part);
};

export const updatePart = async (partID, data) => {
  return await api.put(`/parts/${partID}`, data);
};
