import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import Print from "../icons/Print";
import Edit from "../icons/Edit";
import Delete from "../icons/Delete";

type Props = {
  onEdit: () => void;
  onPrint: () => void;
  onDelete: () => void;
  isFinal: boolean;
};

const Actions = ({ onPrint, onEdit, onDelete, isFinal }: Props) => {
  return (
    <ButtonToolbar>
      <ButtonGroup aria-label="action" className="me-2">
        <Button onClick={onPrint} variant="outline-secondary">
          <Print />
        </Button>
        {isFinal || (
          <Button onClick={onEdit} variant="outline-secondary">
            <Edit />
          </Button>
        )}
      </ButtonGroup>
      {isFinal || (
        <ButtonGroup aria-label="delete">
          <Button onClick={onDelete} variant="outline-secondary">
            <Delete />
          </Button>
        </ButtonGroup>
      )}
    </ButtonToolbar>
  );
};

export default Actions;
