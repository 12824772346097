import Form from "react-bootstrap/Form";
import { useState } from "react";
import { useCustomerQuery } from "../customer/hooks";

type Props = { onChange: (e: any) => void; value: string };

const ALL_CUSTOMER_NAME = "כל הלקוחות";
const ALL_CUSTOMER_ID = "0";

const CustomerSelect = ({ onChange, value }: Props) => {
  const [selected, setSelected] = useState(value);
  let options = [{ id: ALL_CUSTOMER_ID, name: ALL_CUSTOMER_NAME }];

  //for
  const { isLoading, isError, isSuccess, data } = useCustomerQuery({
    active: true,
  });
  if (isLoading || isError) {
    //do nothing
  }

  if (isSuccess) {
    const opt = data.body.map((e: any) => {
      return { id: e.id, name: e.name };
    });

    options = [...options, ...opt];
  }

  return (
    <Form.Select
      onChange={(e) => {
        setSelected(e.target.value);
        onChange(e);
      }}
      value={selected}
    >
      {options.map((op) => {
        return (
          <option key={op.id} value={"" + op.id}>
            {op.name}
          </option>
        );
      })}
    </Form.Select>
  );
};

CustomerSelect.ALL_CUSTOMER = {
  value: ALL_CUSTOMER_ID,
  name: ALL_CUSTOMER_NAME,
};

export default CustomerSelect;
