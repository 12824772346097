import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { ButtonToolbar } from "react-bootstrap";
import XCircle from "../../icons/XCircle";

type Props = {
  onCloseButcherBatch: () => void;
  onSaveButcherBatch: () => void;
  onFinalSaveButcherBatch: () => void;
  synchButcherBatch: boolean;
  canFinalSave: boolean;
};

const ControlActions = ({
  onSaveButcherBatch,
  onCloseButcherBatch,
  synchButcherBatch,
  onFinalSaveButcherBatch,
  canFinalSave,
}: Props) => {
  return (
    <ButtonToolbar>
      <ButtonGroup className="me-3">
        <Button
          onClick={onSaveButcherBatch}
          variant="primary"
          disabled={synchButcherBatch}
        >
          שמירה
        </Button>
        <Button
          onClick={onFinalSaveButcherBatch}
          variant="success"
          disabled={!canFinalSave}
        >
          שמירה סופית
        </Button>
      </ButtonGroup>
      <ButtonGroup>
        <Button onClick={onCloseButcherBatch} variant="outline-secondary">
          <XCircle /> {"  "} סגירה
        </Button>
      </ButtonGroup>
    </ButtonToolbar>
  );
};

export default ControlActions;
