import RawMaterial from "./RawMaterial.js";
import Part from "./Part.js";

/*
 * ButcherManager manages the butchering of raw materials to parts.
 *
 */
class ButcherManager {
  #data = [];
  #id;
  #rawMaterialIDIndex = new Map();
  constructor(batchId) {
    this.#id = batchId;
  }

  //set a new raw material or updates an existing one.
  setRawMaterial(raw) {
    if (this.#rawMaterialIDIndex.has(raw.id)) {
      //update
      const index = this.#rawMaterialIDIndex.get(raw.id);
      //force a new array
      this.#data = [...this.#data];
      this.#data[index] = raw;
      return;
    }
    //add
    const index = this.#data.length;
    this.#data = [...this.#data, raw];
    this.#rawMaterialIDIndex.set(raw.id, index);
  }

  /*
   * Get the raw part associated with the specified id, or undefined if the key can't be found.
   */
  getRawMaterial(rawId) {
    const index = this.#rawMaterialIDIndex.get(rawId);
    if (index === undefined) {
      return undefined;
    }
    return this.#data[index];
  }

  set data(data) {
    this.#data = data;
  }

  get length() {
    return this.#data.length;
  }

  get data() {
    return this.#data;
  }

  get batchId() {
    return this.#id;
  }

  //isDone - each of the raw materials weights is equal to the parts weights
  isDone() {
    for (const r of this.#data) {
      if (!r.isDone()) {
        return false;
      }
    }
    return true;
  }

  static load(batchID, data) {
    const mgr = new ButcherManager(batchID);
    data.forEach((rawmaterial) => {
      let r = new RawMaterial(
        rawmaterial.id,
        rawmaterial.meta.id,
        rawmaterial.meta.raw_weight,
        rawmaterial.meta.slaughter_id,
        rawmaterial.meta.strict_weight
      );

      rawmaterial.parts.forEach((part) => {
        let p = new Part(part.part_id, part.name, part.weight);
        r.addPart(p);
      });

      mgr.setRawMaterial(r);
    });
    return mgr;
  }
}

export default ButcherManager;
