import { useQuery } from "react-query";
import { fetchCustomer } from "../../customer/queries";
import DisplayAtom from "../../DisplayAtom";

type Props = {
  id: number;
};

const CustomerCell = ({ id }: Props) => {
  const { isLoading, isError, isSuccess, data } = useQuery(
    ["customers", id],

    () => fetchCustomer(id)
  );

  return (
    <DisplayAtom
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      atom={data?.body?.name}
    />
  );
};

export default CustomerCell;
