import { Table } from "react-bootstrap";
import Product from "./types";
import weightFixed from "../../weight";

type Props = { product_list: Product[] };

const ProductTable = ({ product_list }: Props) => {
  //product list must be sorted "batching" like products for summing - see below
  product_list.sort((p1, p2) => {
    if (p1.name > p2.name) {
      return 1;
    } else if (p1.name < p2.name) {
      return -1;
    }
    return 0;
  });
  let sum = new SumWeight(-1);
  return (
    <Table className="table-sm">
      <thead className="table-secondary">
        <tr>
          <th>מוצר</th>
          <th>משקל</th>
          <th>לקוח</th>
          <th>מס אצווה </th>
        </tr>
      </thead>
      <tbody>
        {product_list.map((product, index) => {
          if (sum.partId !== product.part_id) {
            if (sum.shouldEmitRow()) {
              const total = sum.totalWeight;
              const pId = sum.partId;
              //reinitalize sum
              sum = new SumWeight(product.part_id);
              sum.add(product.weight);
              return [
                <SumRow weight={total} key={`${index}-${pId}`} />,
                <ProductRow {...{ product, index }} key={index} />,
              ];
            }
            //re initialize sum
            sum = new SumWeight(product.part_id);
          }

          sum.add(product.weight);
          //last row?
          if (index === product_list.length - 1) {
            if (!sum.shouldEmitRow()) {
              return <ProductRow {...{ product, index }} key={index} />;
            }
            const total = sum.totalWeight;
            const pId = sum.partId;
            return [
              <ProductRow {...{ product, index }} key={index} />,
              <SumRow weight={total} key={`${index}-${pId}`} />,
            ];
          }
          return <ProductRow {...{ product, index }} key={index} />;
        })}
      </tbody>
    </Table>
  );
};

const SumRow = ({ weight }: { weight: number }) => {
  return (
    <tr>
      <td>סה"כ משקל</td>
      <td>{weightFixed(weight)}</td>
      <td></td>
      <td></td>
    </tr>
  );
};

type ProductRowProps = { product: Product; index: number };
const ProductRow = ({ product }: ProductRowProps) => {
  return (
    <tr>
      <td>{product.name}</td>
      <td>{product.weight}</td>
      <td>{product.customer_name}</td>
      <td>{product.batch_code}</td>
    </tr>
  );
};

class SumWeight {
  weight: number;
  productCount: number;
  pId: number;
  constructor(productId: number) {
    this.pId = productId;
    this.weight = 0;
    this.productCount = 0;
  }

  get partId() {
    return this.pId;
  }

  add(w: number) {
    this.weight += w;
    this.productCount += 1;
  }

  get totalWeight() {
    return this.weight;
  }

  shouldEmitRow() {
    return this.productCount > 1 && this.pId !== -1;
  }
}

export default ProductTable;
