import Button from "react-bootstrap/Button";
import Delete from "../icons/Delete";

const DeleteEntity = ({ onDelete, className = "" }) => {
  return (
    <Button variant="light" onClick={onDelete} className={className}>
      <Delete />
    </Button>
  );
};

export default DeleteEntity;
