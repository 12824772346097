import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Print from "../icons/Print";
import CancelButton from "../form/CancelButton";

function PrintModalComponent({ show, handlePrint, handleCancel, children }) {
  return (
    <Modal show={show} scrollable={true}>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button variant="primary" onClick={handlePrint}>
          <Print /> הדפס
        </Button>
        <CancelButton handleCancel={handleCancel} />
      </Modal.Footer>
    </Modal>
  );
}

export default PrintModalComponent;
