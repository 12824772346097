import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import RestrictedNav from "./RestrictedNav";

export default function Sidebar() {
  return (
    <Navbar sticky="top" className="flex-column Sidebar">
      <Nav.Item>
        <Nav.Link as={NavLink} to="/goods">
          קבלת סחורה
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/batches">
          אצוות
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/butcher">
          פירוק אצוות
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/inventory">
          מלאי
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={NavLink} to="/productsheet">
          ליקוט
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link as={NavLink} to="/productionlog">
          יומן ייצור
        </Nav.Link>
      </Nav.Item>
      <RestrictedNav roles={["admin"]} to="/suppliers" name="ספקים" />
      <RestrictedNav roles={["admin"]} to="/kinds" name="לקוחות" />
      <RestrictedNav roles={["admin"]} to="/rawparts" name="חלקים גולמיים" />
      <RestrictedNav roles={["admin"]} to="/parts" name="חלקים" />
      <RestrictedNav roles={["admin"]} to="/users" name="עובדים" />
    </Navbar>
  );
}
