import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Spinner from "react-bootstrap/Spinner";
import { useUser } from "../contexts/UserProvider";
import Button from "react-bootstrap/Button";
import ExitIcon from "./icons/Exit";
import env, { PRODUCTION } from "../env/env";

function Header() {
  const { user, logout } = useUser();
  const headerClz = env(PRODUCTION) ? "Header" : "HeaderLocal";

  return (
    <Navbar bg="light" sticky="top" className={headerClz}>
      <Container>
        <Navbar.Brand>
          {user ? "שלוֹם " + user.first : "Meat-Math"}
        </Navbar.Brand>
        <Nav>
          {user === undefined ? (
            <Spinner animation="border" />
          ) : (
            <>
              {user !== null && (
                <div className="justify-content-end">
                  <Exit logout={logout} />
                </div>
              )}
            </>
          )}
        </Nav>
      </Container>
    </Navbar>
  );
}

export default Header;

const Exit = ({ logout }) => {
  return (
    <Button variant="secondary" className="btn-sm" onClick={logout}>
      <ExitIcon /> יציאה
    </Button>
  );
};
