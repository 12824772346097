import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import Container from "react-bootstrap/Container";
import Header from "./components/Header";
import LoginPage from "./pages/Login";
import UsersPage from "./pages/Users";
import GoodsPage from "./pages/Goods";
import PartsPage from "./pages/Parts";
import RawPartsPage from "./pages/RawParts";
import CustomersPage from "./pages/Customers";
import BatchesPage from "./pages/Batches";
import SuppliersPage from "./pages/Suppliers";
import ButcherPage from "./pages/Butcher";
import InventoryPage from "./pages/Inventory";
import ProductionLogPage from "./pages/ProductionLog";
import UserProvider from "./contexts/UserProvider";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import initialize from "./init/initialize";
import FlashProvider from "./contexts/FlashProvider";
import ProductSheetPage from "./pages/ProductSheet";

const queryClient = new QueryClient();

function App() {
  initialize();
  return (
    <Container fluid className="App">
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <UserProvider>
            <FlashProvider>
              <Header />
              <Routes>
                <Route
                  path="/login"
                  element={
                    <PublicRoute>
                      <LoginPage />
                    </PublicRoute>
                  }
                />

                <Route
                  path="/suppliers"
                  element={
                    <PrivateRoute roles={["admin"]}>
                      <SuppliersPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/kinds"
                  element={
                    <PrivateRoute roles={["admin"]}>
                      <CustomersPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/parts"
                  element={
                    <PrivateRoute roles={["admin"]}>
                      <PartsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/rawparts"
                  element={
                    <PrivateRoute roles={["admin"]}>
                      <RawPartsPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/users"
                  element={
                    <PrivateRoute roles={["admin"]}>
                      <UsersPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="*"
                  element={
                    <PrivateRoute roles={["*"]}>
                      <Routes>
                        <Route path="/goods" element={<GoodsPage />} />
                        <Route path="/batches" element={<BatchesPage />} />
                        <Route path="/butcher" element={<ButcherPage />} />
                        <Route
                          path="/productsheet"
                          element={<ProductSheetPage />}
                        />
                        <Route path="/inventory" element={<InventoryPage />} />
                        <Route
                          path="/productionlog"
                          element={<ProductionLogPage />}
                        />
                        <Route path="*" element={<Navigate to="/goods" />} />
                      </Routes>
                    </PrivateRoute>
                  }
                />
              </Routes>
            </FlashProvider>
          </UserProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </Container>
  );
}

export default App;
