import Body from "../components/Body";
import Batches from "../components/Batches";

const BatchesPage = () => {
  return (
    <Body>
      <h2>אצוות</h2>
      <Batches />
    </Body>
  );
};

export default BatchesPage;
