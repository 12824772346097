import { useProductSheetProductsQuery } from "./hooks";
import { ProductSheet as ProductSheetType } from "./types";
import { Spinner } from "react-bootstrap";

type Props = { sheet: ProductSheetType; children: any };

const LoadProductSheetProducts = ({ sheet, children }: Props) => {
  const { isLoading, isError, isSuccess, data } = useProductSheetProductsQuery(
    sheet.id
  );

  let content = null;
  if (isLoading) {
    content = <Spinner animation="border" />;
  }

  if (isError) {
    content = <p>לא ניתן להוריד את הליקוט</p>;
  }

  if (isSuccess) {
    content = children(sheet, data.body);
  }

  return content;
};

export default LoadProductSheetProducts;
