import Button from "react-bootstrap/Button";

type Props = {
  handleOk: () => void;
  disabled?: boolean;
};

const OkButton = ({ handleOk, disabled = false }: Props) => {
  return (
    <Button onClick={handleOk} disabled={disabled} variant="primary">
      שמירה
    </Button>
  );
};

export default OkButton;
