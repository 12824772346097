import Form from "react-bootstrap/Form";
import { Field } from "formik";

function RoleSelect({ name }) {
  return (
    <Field as={Form.Select} name={name}>
      <option value="1">admin</option>
      <option value="2">user</option>
    </Field>
  );
}

export default RoleSelect;
