class Part {
  part = {};
  //a temporary id used for editing
  tmpId;
  /*
   * part_id - the identefier of the part (identifier allocated from backend )
   * name - part name
   * weight - weight of this part
   */
  constructor(id, name, weight) {
    this.part.part_id = id;
    this.part.name = name;
    this.part.weight = weight;
    this.tmpId = String(Math.floor(Math.random() * 10000)) + "_" + id;
  }

  get id() {
    return this.tmpId;
  }
  /*
   * data as object
   */
  get data() {
    return this.part;
  }
  get weight() {
    return this.part.weight;
  }

  toJSON() {
    return this.part;
  }
}

export default Part;
