import Body from "../components/Body";
import Customers from "../components/Customers";

const CustomersPage = () => {
  return (
    <Body>
      <h2>לקוחות</h2>
      <Customers />
    </Body>
  );
};

export default CustomersPage;
