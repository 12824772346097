import { useState } from "react";
import Stack from "react-bootstrap/Stack";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TotalInventoryTab from "./inventory/TotalInventoryTab";
import CustomerSelect from "./customer/CustomerSelect";
import InventoryItemsTab from "./inventory/InventoryItemsTab";
import ActionBar from "./ActionBar";

const Inventory = () => {
  const [customerID, setCustomerID] = useState(CustomerSelect.ALL_CUSTOMER);
  const [key, setKey] = useState<string>("inventoryItems");
  const isShowing = (value: string) => value === key;

  return (
    <Stack className="p-0" gap={4}>
      <ActionBar>
        לקוח:
        <CustomerSelect
          onChange={(e) => {
            const t = e.target;
            setCustomerID({
              value: t.value,
              name: t.options[t.options.selectedIndex].innerHTML,
            });
          }}
          value={customerID.value}
        />
      </ActionBar>

      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k as string)}
        id="inventory"
        className="mb-3"
      >
        <Tab eventKey="inventoryItems" title="מוצרים">
          {isShowing("inventoryItems") && <InventoryItemsTab {...customerID} />}
        </Tab>

        <Tab eventKey="inventoryReport" title="דוח מלאי">
          {isShowing("inventoryReport") && (
            <TotalInventoryTab {...customerID} />
          )}
        </Tab>
      </Tabs>
    </Stack>
  );
};

export default Inventory;
