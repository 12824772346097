import { Button, ButtonGroup } from "react-bootstrap";
import List from "../icons/List";
import Sticky from "../icons/Sticky";
import Delete from "../icons/Delete";

type Props = {
  onPrintLabel: () => void;
  onPrintBatch: () => void;
  onDelete: () => void;
  canDelete: boolean;
};

const Actions = ({
  canDelete,
  onDelete,
  onPrintBatch,
  onPrintLabel,
}: Props) => {
  return (
    <ButtonGroup aria-label="batch">
      <Button onClick={onPrintLabel} variant="outline-secondary">
        <Sticky />
      </Button>
      <Button onClick={onPrintBatch} variant="outline-secondary">
        <List />
      </Button>
      {canDelete && (
        <Button onClick={onDelete} variant="outline-secondary">
          <Delete />
        </Button>
      )}
    </ButtonGroup>
  );
};

export default Actions;
