import { useState } from "react";
import MainPanel from "./batch/butcher/MainPanel";
import Part from "./batch/butcher/manager/Part";
import { useBatchProcessingSaveMutation } from "./batch/hooks";
import { useProductsSaveMutation } from "./product/hooks";
import { useFlash } from "../contexts/FlashProvider";
import { useUser } from "../contexts/UserProvider";

type Props = {
  onBatchClose: () => void;
  butcherMgr: any;
};

const Butcher = ({ onBatchClose, butcherMgr }: Props) => {
  const [data, setData] = useState<null | any[]>(butcherMgr.data);
  //Butcher data is synched (saved) on the backend
  const [synchBatch, setSynchBatch] = useState<boolean>(true);
  const [isFinalSave, setIsFinalSave] = useState<boolean>(butcherMgr.isDone());
  const flash = useFlash();
  const { isAdmin } = useUser();

  const errorMsg = () => {
    flash("האצוות לא נשמרו", "danger");
  };

  //upsert processing data
  const upsert = useBatchProcessingSaveMutation({
    onSuccess: () => {
      setSynchBatch(true);
    },
    onError: () => {
      errorMsg();
      setSynchBatch(false);
    },
  });

  const createProducts = useProductsSaveMutation({
    onSuccess: () => {
      onBatchClose();
    },
    onError: () => {
      errorMsg();
    },
  });

  const handleBatchSave = () => {
    //processing data.. save it
    upsert.mutate({
      id: butcherMgr.batchId,
      data: JSON.stringify(butcherMgr.data),
    });
  };

  const handleBatchFinalSave = () => {
    createProducts.mutate({
      batch_id: butcherMgr.batchId,
      rawmaterials: butcherMgr.data,
    });
  };

  const handleAddPart = (nPart: any) => {
    let rm = butcherMgr.getRawMaterial(nPart.raw_id);
    if (rm === undefined) {
      return;
    }
    rm.addPart(
      new Part(nPart.part.part_id, nPart.part.name, nPart.part.weight)
    );
    butcherMgr.setRawMaterial(rm);
    setData(butcherMgr.data);
    setIsFinalSave(butcherMgr.isDone());
    setSynchBatch(false);
  };

  const handleRemovePart = (ids: { raw_id: any; part_id: any }) => {
    let rm = butcherMgr.getRawMaterial(ids.raw_id);
    if (rm === undefined) {
      return;
    }
    rm.removePart(ids.part_id);
    butcherMgr.setRawMaterial(rm);
    setData(butcherMgr.data);
    setIsFinalSave(butcherMgr.isDone());
    setSynchBatch(false);
  };

  return (
    <MainPanel
      rawmaterial={data}
      onBatchClose={onBatchClose}
      onBatchSave={handleBatchSave}
      synchBatch={synchBatch}
      onBatchFinalSave={handleBatchFinalSave}
      isFinalSave={isFinalSave || isAdmin()} //admin can overide and save partial
      onRemovePart={handleRemovePart}
      onAddPart={handleAddPart}
    />
  );
};

export default Butcher;
