import ProductSheetTablePanel from "./ProductSheetTablePanel";
import useCRUDDialogs from "../crud/hooks";
import { useState } from "react";
import PrintComponent from "../print/PrintComponent";
import LoadProductSheetProducts from "./LoadProductSheetProducts";
import { ProductSheet as ProductSheetType } from "./types";
import { confirm } from "../form/ConfirmDialog";
import { information } from "../form/InformationDialog";
import {
  useProductSheetDeleteMutation,
  useProductSheetIsFinalMutation,
} from "./hooks";
import CollectProduct from "./collect/CollectProduct";
import ProductSheet from "./ProductSheet";

type Props = { customer_id: string };

const ProductSheetPanel = (p: Props) => {
  const [sheet, setSheet] = useState<ProductSheetType>();

  const {
    setShowCreate: setShowPrint,
    isCreateShowing: isPrintShowing,
    setShowUpdate,
    isUpdateShowing,
    setClose,
  } = useCRUDDialogs();

  const isFinalMutation = useProductSheetIsFinalMutation(setClose, () =>
    information("אירעה שגיאה")
  );

  const deleteMutation = useProductSheetDeleteMutation(() =>
    information("אירעה שגיאה")
  );

  const handleDelete = async (sheet: ProductSheetType) => {
    if (!(await confirm(`מחק דף לקוח ${sheet.customer_name}?`))) {
      return;
    }
    //@ts-ignore
    deleteMutation.mutate(sheet.id);
  };

  const onAction = async (action: string, sheet: ProductSheetType) => {
    switch (action) {
      case "print":
        setShowPrint();
        break;
      case "edit":
        setShowUpdate();
        break;
      case "delete": {
        handleDelete(sheet);
        setSheet(undefined);
        return;
      }
    }
    setSheet(sheet);
  };

  return (
    <>
      {isPrintShowing() && (
        <PrintComponent show={isPrintShowing()} cancel={setClose}>
          <LoadProductSheetProducts sheet={sheet as any as ProductSheetType}>
            {(sheet: any, data: any) => (
              <ProductSheet
                product_list={data}
                customer_name={sheet.customer_name}
                created_at={sheet.created_at}
              />
            )}
          </LoadProductSheetProducts>
        </PrintComponent>
      )}
      {isUpdateShowing() && (
        <CollectProduct
          sheet={sheet!}
          onClose={setClose}
          onSave={() =>
            isFinalMutation.mutate({ sheetID: sheet?.id, isFinal: true } as any)
          }
        />
      )}

      <ProductSheetTablePanel key={p.customer_id} {...p} onAction={onAction} />
    </>
  );
};

export default ProductSheetPanel;
