import { api } from "../../ApiClient";

export const fetchCustomer = async (customerID) => {
  return await api.get(`/customers/${customerID}`);
};

export const fetchCustomers = async (params) => {
  return await api.get("/customers", params);
};

export const createCustomer = async (customer) => {
  return await api.post("/customers", customer);
};

export const updateCustomer = async (customerID, data) => {
  return await api.put(`/customers/${customerID}`, data);
};
