import { batchName, resetBatchNumber } from "./util";
import RawMaterial from "../rawmaterial/rawmaterial";

const initData = {
  batches_order: [],
  batches: {},
  raw_materials_collection: {},
  raw_materials_available: [],
};

/*
 * Holds the Batches and RawMaterial
 */
class BatchManager {
  #data = initData;
  constructor(data = null) {
    if (data !== null) {
      this.data = data;
    }
  }

  set data(data) {
    this.#data = data;
  }

  get data() {
    return this.#data;
  }

  reset() {
    this.data = {
      batches_order: [],
      batches: {},
      raw_materials_available: [],
      raw_materials_collection: {},
    };
  }

  canSave() {
    //check for non emtpy batches
    for (let id of this.#data.batches_order) {
      if (this.#data.batches[id].raw_material_ids.length === 0) {
        return false;
      }
    }

    //and that all raw materials were assigend
    return (
      this.#data.raw_materials_available.length === 0 && //no more to assign
      Object.keys(this.#data.raw_materials_collection).length > 0 //at least one
    );
  }

  rawMaterialMoved(source, destination, rawId) {
    if (source.droppableId === "rawMaterialArea") {
      this.rawFromMaterialAreaToBatch(source, destination, rawId);
    } else if (destination.droppableId === "rawMaterialArea") {
      this.rawFromBatchToRawMaterialArea(source, rawId);
    } else {
      this.rawFromBatchToBatch(source, destination, rawId);
    }
  }

  rawFromBatchToBatch(source, destination, rawId) {
    if (source.droppableId === destination.droppableId) {
      //no change
      return;
    }

    let data = this.cloneDataState();
    //remove from a batch
    data.batches[source.droppableId].raw_material_ids.splice(source.index, 1);
    //add to a batch
    data.batches[destination.droppableId].raw_material_ids.push(rawId);
    this.data = data;
  }

  rawFromBatchToRawMaterialArea(source, rawId) {
    let data = this.cloneDataState();
    //remove to a batch
    data.batches[source.droppableId].raw_material_ids.splice(source.index, 1);
    //add from the raw material first
    data.raw_materials_available.push(rawId);
    this.data = data;
  }

  rawFromMaterialAreaToBatch(source, destination, rawId) {
    let data = this.cloneDataState();
    //remove from the raw material first
    data.raw_materials_available.splice(source.index, 1);
    //add to a batch
    data.batches[destination.droppableId].raw_material_ids.push(rawId);
    this.data = data;
  }

  removeRawMaterial(id) {
    let data = this.cloneDataState();
    delete data.raw_materials_collection[id];
    data.raw_materials_available = data.raw_materials_available.filter(
      (cid) => cid !== id
    );
    this.data = data;
  }

  addRawMaterial(raw) {
    let data = this.cloneDataState();
    const newRaw = new RawMaterial(raw);

    data.raw_materials_collection[newRaw.id] = newRaw;
    data.raw_materials_available.push(newRaw.id);
    this.data = data;
  }

  hasBatches() {
    return this.#data.batches_order.length !== 0;
  }
  //remove all raw material from batches
  //remove all batches
  resetBatches() {
    let data = this.cloneDataState();
    for (let id of data.batches_order) {
      const fromBatch = data.batches[id].raw_material_ids;
      const rawAvail = data.raw_materials_available;
      //merge batch raw ids to available
      data.raw_materials_available = [...rawAvail, ...fromBatch];
      //clear batch raw ids
      data.batches[id].raw_material_ids = [];
    }
    //reset all batches
    data.batches_order = [];
    resetBatchNumber();
    this.#data = data;
  }

  async addBatch() {
    let data = this.cloneDataState();
    const name = await batchName(data.batches_order.length === 0);
    const id = name;
    const newBatch = { name, id, raw_material_ids: [] };
    data.batches_order.unshift(newBatch.id);
    data.batches[newBatch.id] = newBatch;
    this.#data = data;
  }

  cloneDataState() {
    return {
      batches_order: [...this.#data.batches_order],
      batches: { ...this.#data.batches },
      raw_materials_available: [...this.#data.raw_materials_available],
      raw_materials_collection: { ...this.#data.raw_materials_collection },
    };
  }

  get batchesList() {
    return this.#data.batches_order;
  }

  /**
   * export a batch as an object
   * batchId - a batch id
   * throw exception if no id was found
   */
  export(batchId) {
    if (!(batchId in this.#data.batches)) {
      throw Error(`batch id not found. id=${batchId}`);
    }

    let btch = this.#data.batches[batchId];
    const exportRaw = (id) => this.#data.raw_materials_collection[id].export();
    let raws = btch.raw_material_ids.map(exportRaw);
    const out = {
      code: btch.id,
      raw_materials: raws,
    };
    return out;
  }
}

export default BatchManager;
