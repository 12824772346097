import { Navigate } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";

type Props = {
  children: JSX.Element;
};

const PublicRoute = ({ children }: Props) => {
  const { user } = useUser();

  //loading the user
  if (user === undefined) {
    return null;
  }

  //user is logged - dont show her the route
  if (user) {
    return <Navigate to="/" />;
  }

  return children;
};

export default PublicRoute;
