import { api } from "../../ApiClient";

export const createProducts = async (products: {
  batch_id: number;
  rawmaterials: any;
}) => {
  return await api.post("/products", products);
};

export const associateProductProductSheet = async (
  productID: number,
  sheetID: number
) => {
  return await api.patch(`/products/${productID}`, {
    product_sheet_id: sheetID,
  });
};
