import { Table } from "react-bootstrap";
import Product from "../types";
import DeleteEntity from "../../form/DeleteEntity";
import DateLocale from "../../DateLocale";

type Props = { product_list: Product[]; onRemove: (p: Product) => void };

const AddedProductTable = ({ product_list, onRemove }: Props) => {
  //sort for summing - see below
  product_list.sort((p1, p2) => {
    if (p1.name > p2.name) {
      return 1;
    } else if (p1.name < p2.name) {
      return -1;
    }
    return 0;
  });

  return (
    <Table className="table-sm">
      <thead className="table-secondary">
        <tr>
          <th>מוצר</th>
          <th>משקל</th>
          <th>תאריך ייצור</th>
          <th>לקוח</th>
          <th style={{ width: "10%" }}></th>
        </tr>
      </thead>
      <tbody>
        {product_list.map((product, index) => {
          return (
            <ProductRow {...{ product, index, onRemove }} key={product.id} />
          );
        })}
      </tbody>
    </Table>
  );
};

type ProductRowProps = {
  product: Product;
  index: number;
  onRemove: (p: Product) => void;
};
const ProductRow = ({ index, product, onRemove }: ProductRowProps) => {
  return (
    <tr key={index}>
      <td>{product.name}</td>
      <td>{product.weight}</td>
      <td>
        <DateLocale utcDate={product.created_at} />
      </td>
      <td>{product.customer_name}</td>
      <td>
        <DeleteEntity className="p-0" onDelete={() => onRemove(product)} />
      </td>
    </tr>
  );
};

export default AddedProductTable;
