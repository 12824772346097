import Table from "react-bootstrap/Table";
import RawPartRow from "./RawPartRow";

const RawPartsTable = ({ parts, handleEdit }) => {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>שם</th>
          <th style={{ width: "70%" }}>חלקים</th>
          <th>משקל מחמיר</th>
          <th>פעיל</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {parts.map((part) => {
          return (
            <RawPartRow key={part.id} part={part} handleEdit={handleEdit} />
          );
        })}
      </tbody>
    </Table>
  );
};

export default RawPartsTable;
