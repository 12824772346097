import Container from "react-bootstrap/Container";
import { StrictModeDroppable as Droppable } from "../../dragndrop/StrictModeDroppable";
import RawMaterialCollection from "./RawMaterialCollection";

const RawMaterialArea = ({ collection, handleRemove, available }) => {
  let notAssigend = available.map((id) => collection[id]);

  return (
    <Container className="RawMaterialArea card-group">
      <Droppable droppableId="rawMaterialArea">
        {(provided, snapshot) => (
          <RawMaterialCollection
            available={notAssigend}
            provided={provided}
            snapshot={snapshot}
            handleRemove={handleRemove}
          ></RawMaterialCollection>
        )}
      </Droppable>
    </Container>
  );
};

export default RawMaterialArea;
