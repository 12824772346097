import { useState } from "react";
import Container from "react-bootstrap/Container";
import { Spinner } from "react-bootstrap";
import Vertical from "./layout/Vertical";
import ActionBar from "./ActionBar";
import BatchSelect from "./batch/butcher/BatchSelect";
import ButcherManager from "./batch/butcher/manager/ButcherManager";
import Butcher from "./Butcher";
import { useBatchProcessingQuery } from "./batch/hooks";

const SELECT_BATCH = -1;
const ButcherBatchSelector = () => {
  const [batchID, setBatchID] = useState(SELECT_BATCH);

  const handleBatchSelected = (e: any) => {
    let id = e.target.value === "" ? SELECT_BATCH : e.target.value;
    setBatchID(parseInt(id));
  };

  const handleBatchClose = () => {
    setBatchID(SELECT_BATCH);
  };

  const { isIdle, isLoading, isError, isSuccess, data } =
    useBatchProcessingQuery(batchID, batchID !== SELECT_BATCH);
  let content = null;
  if (isIdle) {
    content = null;
  }
  if (isLoading) {
    content = <Spinner animation="border" />;
  }
  if (isError) {
    content = <p>לא ניתן להוריד את האצווה </p>;
  }

  if (isSuccess) {
    let obj = data.body;
    try {
      let mData = JSON.parse(obj.data);
      let mgr = ButcherManager.load(batchID, mData);
      content = <Butcher onBatchClose={handleBatchClose} butcherMgr={mgr} />;
    } catch (e) {
      content = <p>לא ניתן להוריד את האצווה </p>;
    }
  }

  return (
    <Vertical gap={4}>
      <Container>
        <ActionBar dir={"rtl"}>
          <div className="text-nowrap">אצווה לפירוק: </div>
          <div>
            <BatchSelect
              selected={batchID === SELECT_BATCH ? "" : batchID}
              onChange={handleBatchSelected}
              disabled={batchID !== SELECT_BATCH && isSuccess}
            />
          </div>
        </ActionBar>
      </Container>
      {content}
    </Vertical>
  );
};

export default ButcherBatchSelector;
