/*
 * which env we are in
 */
export const PRODUCTION = "production";
export const LOCACAL = "local";

const env = (...envs) => {
  //sanity
  if (window.__ENV__ === undefined) {
    return false;
  }
  for (const e of envs) {
    if (window.__ENV__.indexOf(e) === 0) {
      return true;
    }
  }
};

export default env;
