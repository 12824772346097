import { useContext } from "react";
import { FlashContext } from "../contexts/FlashProvider";
import { Collapse } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";

const FlashMessage = () => {
  const { flashMessage, visible, hideFlash } = useContext(FlashContext);

  return (
    <Collapse in={visible} className="mt-1">
      <div>
        <Alert
          variant={flashMessage.type || "info"}
          dismissible
          onClose={hideFlash}
        >
          {flashMessage.message}
        </Alert>
      </div>
    </Collapse>
  );
};

export default FlashMessage;
