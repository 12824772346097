import { useQuery } from "react-query";
import DynamicSelect from "../../form/DynamicSelect";
import { fetchRawParts } from "../../part/queries";
import { createKey } from "./utils";

function RawPartSelect({ name }) {
  let options = [{ id: "", name: "בחר" }];
  //for
  const { isLoading, isError, isSuccess, data } = useQuery(
    ["rawparts"],

    () => fetchRawParts()
  );

  if (isLoading || isError) {
    //do nothing
  }

  if (isSuccess) {
    const active = data.body.filter((v) => v.active);
    const opt = active.map((e) => {
      return { id: createKey(e), name: e.name };
    });

    options = [...options, ...opt];
  }

  return <DynamicSelect name={name} options={options} />;
}

export default RawPartSelect;
