import { useQuery } from "react-query";
import { fetchParts } from "./queries";

const usePartLoader = () => {
  return useQuery(
    ["parts"],

    () => fetchParts({ active: 1 })
  );
};

export { usePartLoader };
