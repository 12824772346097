import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import MainPanelHeader from "./MainPanelHeader";
import RawPartPanel, { NewPartType } from "./RawPartPanel";

type Props = {
  onBatchSave: () => void;
  onBatchClose: () => void;
  synchBatch: boolean;
  onBatchFinalSave: () => void;
  isFinalSave: boolean;
  onAddPart: (nPart: NewPartType) => void;
  onRemovePart: (ids: { raw_id: any; part_id: any }) => void;
  rawmaterial: any[] | null;
};

const MainPanel = ({
  rawmaterial,
  onBatchClose,
  onBatchSave,
  synchBatch,
  onBatchFinalSave,
  isFinalSave,
  onAddPart,
  onRemovePart,
}: Props) => {
  return (
    <Container>
      <MainPanelHeader
        onBatchClose={onBatchClose}
        onBatchSave={onBatchSave}
        synchBatch={synchBatch}
        onFinalSave={onBatchFinalSave}
        isFinalSave={isFinalSave}
      />
      <div className="Butcher-MainPanelBodyViewPort">
        <Stack gap={4}>
          {rawmaterial &&
            rawmaterial.map((rm) => (
              <RawPartPanel
                key={rm.id}
                {...rm.data}
                onAddPart={onAddPart}
                onRemovePart={onRemovePart}
              />
            ))}
        </Stack>
      </div>
    </Container>
  );
};

export default MainPanel;
