import { useState } from "react";

const ShowNullDialog = 0;
const ShowCreateDialog = 1;
const ShowUpdateDialog = 2;
const ShowDeleteDialog = 3;

const useCRUDDialogs = () => {
  const [showDialog, setShowDialog] = useState(ShowNullDialog);
  const setShowCreate = () => setShowDialog(ShowCreateDialog);
  const setShowUpdate = () => setShowDialog(ShowUpdateDialog);
  const setShowDelete = () => setShowDialog(ShowDeleteDialog);
  const setClose = () => setShowDialog(ShowNullDialog);
  const isCreateShowing = () => showDialog === ShowCreateDialog;
  const isUpdateShowing = () => showDialog === ShowUpdateDialog;
  const isDeleteShowing = () => showDialog === ShowDeleteDialog;

  return {
    setShowCreate,
    isCreateShowing,
    setShowUpdate,
    isUpdateShowing,
    setShowDelete,
    isDeleteShowing,
    setClose,
  };
};

export default useCRUDDialogs;
