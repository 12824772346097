import Body from "../components/Body";
import ProductSheet from "../components/ProductSheet";

const ProductSheetPage = () => {
  return (
    <Body>
      <h2>דפי ליקוט</h2>
      <ProductSheet />
    </Body>
  );
};

export default ProductSheetPage;
