import { useState } from "react";
import { SortingState } from "@tanstack/react-table";
import { Spinner } from "react-bootstrap";
import { useInventoryQuery } from "../../inventory/hooks";
import { Paginator } from "../../pagination/Paginator";
import useOnPage from "../../pagination/hooks";
import AvailableProductTable from "./AvailableProductTable";
import { useProductProductSheetlMutation } from "../../product/hooks";

type Props = { customer_id: string; sheet_id: number };
const sortingDefault: SortingState = [];

const AvailableProductSelector = ({ customer_id, sheet_id }: Props) => {
  const [sorting, setSorting] = useState<SortingState>(sortingDefault);
  const [page, handlePrev, handleNext] = useOnPage(1);
  const noop = () => {};
  const associateProductSheet = useProductProductSheetlMutation({
    onSuccess: noop,
    onError: noop,
  });

  const handleAssociateProductToProductSheet = (productID: number) => {
    associateProductSheet.mutate({ productID, sheetID: sheet_id });
  };

  const { isLoading, isError, isSuccess, data } = useInventoryQuery(
    customer_id,
    { page, pageSize: Paginator.defaultPageSize },
    sorting
  );

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (isError) {
    return <p>לא ניתן להוריד את דוח</p>;
  }

  if (isSuccess) {
    const pg = data.body.meta.pagination;
    const onPage = Paginator.onPage(pg.page, pg.page_size, pg.total);
    return (
      <Paginator
        handlePrev={handlePrev}
        handleNext={handleNext}
        onfirst={onPage.first}
        onlast={onPage.last}
        size="sm"
      >
        <AvailableProductTable
          onAdd={handleAssociateProductToProductSheet}
          data={data.body.data}
          sorting={sorting}
          onSorting={setSorting}
        />
      </Paginator>
    );
  }
};

export default AvailableProductSelector;
