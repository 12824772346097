import { Button } from "react-bootstrap";
import ActionBarBase from "../../ActionBar";

type Props = {
  onClose: () => void;
  onSave: () => void;
};

const ActionBar = ({ onClose, onSave }: Props) => {
  return (
    <ActionBarBase>
      <Button onClick={onSave} variant="primary">
        שמירה סופית
      </Button>
      <Button onClick={onClose} variant="secondary">
        יציאה
      </Button>
    </ActionBarBase>
  );
};

export default ActionBar;
