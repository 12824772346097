import Table from "react-bootstrap/Table";
import UserRow from "./UserRow";

const UsersTable = ({ users, handleEdit, handleDelete }) => {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>שם פרטי</th>
          <th>שם משפחה</th>
          <th>שם משתמש</th>
          <th>תַפְקִיד</th>
          <th>פעיל מתאריך</th>
          <th>פעיל</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          return (
            <UserRow
              key={user.id}
              user={user}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default UsersTable;
