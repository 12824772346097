import {
  flexRender,
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  ColumnDef,
} from "@tanstack/react-table";
import Table from "react-bootstrap/Table";
import ArrowUpDown from "../icons/ArrowUpDown";

type Product = {
  name: string;
  no_of_items: number;
  total_weight: number;
};

type Meta = {
  total_weight: number;
  total_items: number;
};

type Props = { data: Product[]; meta: Meta };

function getColumns(
  total_weight: number,
  total_items: number
): ColumnDef<Product, any>[] {
  const columnHelper = createColumnHelper<Product>();

  return [
    columnHelper.accessor("name", {
      header: "מוצר",
      cell: (props: any) => <>{props.getValue()}</>,
    }),
    columnHelper.accessor("no_of_items", {
      header: `מספר מוצרים (${total_items})`,
      cell: (props: any) => <>{props.getValue()}</>,
    }),
    columnHelper.accessor("total_weight", {
      header: `משקל כולל (${total_weight})`,
      cell: (props: any) => <>{props.getValue()}</>,
    }),
  ];
}

const TotalInventoryTable = ({ data, meta }: Props) => {
  const table = useReactTable({
    data,
    columns: getColumns(meta.total_weight, meta.total_items),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table size="sm">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder ? null : <Header header={header} />}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const Header = ({ header }: { header: any }) => {
  return (
    <>
      {flexRender(header.column.columnDef.header, header.getContext())}
      {header.column.getCanSort() && (
        <div
          className="mx-2 d-inline-block Sorter"
          onClick={header.column.getToggleSortingHandler()}
        >
          <ArrowUpDown />
        </div>
      )}
    </>
  );
};

export default TotalInventoryTable;
