import Table from "react-bootstrap/Table";
import PartRow from "./PartRow";

const PartsTable = ({ parts, handleEdit }) => {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>שם</th>
          <th>תפוגה (ימים)</th>
          <th>פעיל</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {parts.map((part) => {
          return <PartRow key={part.id} part={part} handleEdit={handleEdit} />;
        })}
      </tbody>
    </Table>
  );
};

export default PartsTable;
