type Props = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  atom: string;
};

//Display some data loaded w/ react-query
const DisplayAtom = ({ isLoading, isError, isSuccess, atom }: Props) => {
  let content = null;
  if (isLoading || isError) {
    content = "-";
  }

  if (isSuccess) {
    content = atom;
  }
  return <>{content}</>;
};

export default DisplayAtom;
