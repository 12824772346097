import { Container, Row, Col } from "react-bootstrap";
import ControlActions from "./ControlActions";

type Props = {
  onBatchClose: () => void;
  onBatchSave: () => void;
  synchBatch: boolean;
  onFinalSave: () => void;
  isFinalSave: boolean;
};

const MainPanelHeader = ({
  onBatchClose,
  onBatchSave,
  synchBatch,
  onFinalSave,
  isFinalSave,
}: Props) => {
  return (
    <Container
      style={{ position: "sticky" }}
      className="Butcher-MainPanelHeader"
    >
      <Row>
        <Col className="d-flex justify-content-end">
          <ControlActions
            onCloseButcherBatch={onBatchClose}
            onSaveButcherBatch={onBatchSave}
            synchButcherBatch={synchBatch}
            onFinalSaveButcherBatch={onFinalSave}
            canFinalSave={isFinalSave}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MainPanelHeader;
