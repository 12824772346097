import Button from "react-bootstrap/Button";

const SubmitButton = ({ isSubmitting, label = "שמירה" }) => {
  return (
    <Button variant="primary" type="submit" disabled={isSubmitting}>
      {isSubmitting ? "המתן..." : label}
    </Button>
  );
};

export default SubmitButton;
