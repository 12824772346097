import { useState } from "react";
import Table from "react-bootstrap/Table";
import RawMaterialRow from "./RawMaterialRow";
import PrintLabel from "./PrintLabel";

const RawMaterialesTable = ({
  rawmaterials,
  batch_code /*when printing no need for the batch code */,
  isPrint = false,
}) => {
  const [printRaw, setPrintRaw] = useState(null);
  const handlePrint = (raw) => setPrintRaw(raw);
  const cancel = () => setPrintRaw(null);

  return (
    <>
      {printRaw != null && (
        <PrintLabel cancel={cancel} raw={printRaw} batch_code={batch_code} />
      )}
      <Table className="table-sm">
        <thead className="table-secondary">
          <tr>
            <th></th>
            <th>מס' שחיטה</th>
            <th>ספק</th>
            <th>חלק גולמי</th>
            {!isPrint && <th>טמפ' קבלה</th>}
            <th>לקוח</th>
            <th>הערה</th>
            <th>משקל</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rawmaterials.map((rawmaterial, index) => {
            return (
              <RawMaterialRow
                key={rawmaterial.id}
                index={index + 1}
                rawmaterial={rawmaterial}
                onPrint={handlePrint}
                isPrint={isPrint}
              />
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default RawMaterialesTable;
