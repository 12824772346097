import { useState } from "react";
import InventoryItemsTable from "./InventoryItemsTable";
import { SortingState } from "@tanstack/react-table";
import { Spinner } from "react-bootstrap";
import { useInventoryQuery } from "./hooks";
import { Paginator } from "../pagination/Paginator";
import useOnPage from "../pagination/hooks";

type Props = { customer_id: string };
const sortingDefault: SortingState = [];

const InventoryItemsPanel = ({ customer_id }: Props) => {
  const [sorting, setSorting] = useState<SortingState>(sortingDefault);
  const [page, handlePrev, handleNext] = useOnPage(1);

  const { isLoading, isError, isSuccess, data } = useInventoryQuery(
    customer_id,
    { page, pageSize: Paginator.defaultPageSize },
    sorting
  );

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (isError) {
    return <p>לא ניתן להוריד את דוח</p>;
  }

  if (isSuccess) {
    const pg = data.body.meta.pagination;
    const onPage = Paginator.onPage(pg.page, pg.page_size, pg.total);
    return (
      <Paginator
        handlePrev={handlePrev}
        handleNext={handleNext}
        onfirst={onPage.first}
        onlast={onPage.last}
      >
        <InventoryItemsTable
          onSorting={setSorting}
          sorting={sorting}
          data={data.body.data}
        />
      </Paginator>
    );
  }
};

export default InventoryItemsPanel;
