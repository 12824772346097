import React from "react";
import Stack from "react-bootstrap/Stack";
import SubmitButton from "./SubmitButton";
import CancelButton from "./CancelButton";

const SubmitCancel = ({ handleCancel, isSubmitting }) => {
  return (
    <Stack direction="horizontal" gap={3}>
      <SubmitButton isSubmitting={isSubmitting} />
      <CancelButton isSubmitting={isSubmitting} handleCancel={handleCancel} />
    </Stack>
  );
};

export default SubmitCancel;
