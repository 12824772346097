import Body from "../components/Body";
import Parts from "../components/Parts";

const PartsPage = () => {
  return (
    <Body>
      <h2>חלקים</h2>
      <Parts />
    </Body>
  );
};

export default PartsPage;
