import Container from "react-bootstrap/Container";
import ActionBar from "../ActionBar";
import NewEntity from "../form/NewEntity";
import BatchList from "./BatchList";
import RawMaterialArea from "./RawMaterialArea";
import { DragDropContext } from "react-beautiful-dnd";
import Vertical from "../layout/Vertical";
import OkButton from "../form/OkButton";
import ResetButton from "../form/ResetButton";

const Panel = ({
  isSubmitting,
  data,
  control,
  handleNewBatch,
  handleBatchReset,
  handleDragEnd,
  handleNewRawMaterial,
  handleRemoveRawMaterial,
  handleBatchSave,
}) => {
  return (
    <Vertical gap={4}>
      <Container>
        <ActionBar>
          <OkButton
            disabled={!control.canSave || isSubmitting}
            handleOk={handleBatchSave}
          />
        </ActionBar>
      </Container>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Container className="BatchPanel">
          <Vertical>
            <ActionBar dir={"rtl"}>
              <NewEntity label="הוספת חומר גלם" onNew={handleNewRawMaterial} />
            </ActionBar>
            <RawMaterialArea
              collection={data.raw_materials_collection}
              available={data.raw_materials_available}
              handleRemove={handleRemoveRawMaterial}
            />
          </Vertical>
        </Container>
        <Container className="BatchPanel">
          <Vertical>
            <ActionBar dir={"rtl"}>
              <NewEntity label="הוספת אצווה" onNew={handleNewBatch} />
              <ResetButton
                onReset={handleBatchReset}
                disabled={!control.hasBatches}
              />
            </ActionBar>
            <div
              style={{ gap: "12px 12px" }}
              className="d-flex justify-content-around flex-row flex-wrap align-items-baseline"
            >
              <BatchList data={data} />
            </div>
          </Vertical>
        </Container>
      </DragDropContext>
    </Vertical>
  );
};

export default Panel;
