import { api } from "../../ApiClient";

export const fetchUsers = async (incDeleted) => {
  return await api.get("/users", {
    inc_deleted: incDeleted,
  });
};

export const createUser = async (user) => {
  return await api.post("/users", user);
};

export const deleteUser = async (userID) => {
  return await api.delete(`/users/${userID}`);
};

export const updateUser = async (userID, data) => {
  return await api.put(`/users/${userID}`, data);
};
