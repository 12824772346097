//https://dev.to/alldanielscott/how-to-compare-numbers-correctly-in-javascript-1l4i
//

//compare weights with an epsilon error

function equalWeights(w1, w2) {
  return Math.abs(w1 - w2) <= 0.05;
}

export default equalWeights;
