import Button from "react-bootstrap/Button";
import Plus from "../icons/Plus";

type props = {
  onNew: () => void;
  label?: string;
  disabled?: boolean;
  className?: string;
};

const NewEntity = ({ onNew, label, className, disabled = false }: props) => {
  return (
    <Button
      variant="primary"
      className={className}
      disabled={disabled}
      onClick={onNew}
    >
      <Plus /> {label === undefined ? "יצירה" : label}
    </Button>
  );
};

export default NewEntity;
