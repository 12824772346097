import { useMutation, useQueryClient } from "react-query";
import { associateProductProductSheet, createProducts } from "./queries";

type CallBacks = {
  onSuccess: () => void;
  onError: () => void;
};

const useProductProductSheetlMutation = ({ onSuccess, onError }: CallBacks) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (vars: { productID: number; sheetID: number }) =>
      associateProductProductSheet(vars.productID, vars.sheetID),
    onSuccess: (_data, vars) => {
      queryClient.invalidateQueries({
        queryKey: ["productsheets", vars.sheetID],
      });
      queryClient.invalidateQueries({
        queryKey: ["products"],
      });
      onSuccess();
    },
    onError: onError,
  });
};

//for creating products
const useProductsSaveMutation = ({ onSuccess, onError }: CallBacks) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (vars: { batch_id: number; rawmaterials: any }) =>
      createProducts(vars),
    onSuccess: (_dat, _variables) => {
      queryClient.invalidateQueries({
        queryKey: ["products"],
      });
      //creating a product also mutates the batches; invalidate
      queryClient.invalidateQueries({
        queryKey: ["batches"],
      });

      onSuccess();
    },
    onError: onError,
  });
};

export { useProductsSaveMutation, useProductProductSheetlMutation };
