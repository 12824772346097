import RawMaterialMeta from "./RawMaterialMeta.js";

class RawMaterial {
  rawmaterial = {};
  meta = null;

  constructor(id, name, weight, slaughterId, strictWeight) {
    this.meta = new RawMaterialMeta(name, weight, 0, slaughterId, strictWeight);
    this.rawmaterial = this.initialize(id, this.meta.data, []);
  }

  get id() {
    return this.rawmaterial.id;
  }

  /*
   * data as object
   */
  get data() {
    return this.rawmaterial;
  }

  /**
   * Add part
   */
  addPart(part) {
    let id = this.rawmaterial.id;
    this.meta.addPartWeight(part.weight);
    let parts = [...this.rawmaterial.parts, part];
    this.rawmaterial = this.initialize(id, this.meta.data, parts);
  }

  removePart(id) {
    let toRemove = null;
    let parts = this.rawmaterial.parts.filter((part) => {
      if (part.id === id) {
        toRemove = part;
        return false;
      }
      return true;
    });

    if (toRemove === null) {
      return;
    }
    //found
    this.meta.addPartWeight(-1 * toRemove.weight);
    let rId = this.rawmaterial.id;
    this.rawmaterial = this.initialize(rId, this.meta.data, parts);
  }

  initialize(id, meta, parts) {
    return { id, meta, parts };
  }

  toJSON() {
    return this.rawmaterial;
  }

  //isDone - the raw material weight is equal to the parts weights
  isDone() {
    return this.meta.isDone();
  }
}

export default RawMaterial;
