//create selectable 'value' key for selec/option element
const createKey = (entity) => [entity.id, entity.name].join("|");

const splitKey = (value) => value.split("|");

//test for valid date: assume date string in  Date().toLocaleDateString("he-IL") format
const validDate = (value) => {
  const parts = value.split(".");
  if (parts.length !== 3) {
    return false;
  }
  if (parts[0] === "" || parts[1] === "" || parts[2] === "") {
    return false;
  }
  //parse date assumes dd.mm.yyyy
  const ts = Date.parse(parts[2] + "-" + parts[1] + "-" + parts[0]);
  return !isNaN(ts);
};

const dateFromString = (value) => {
  if (!validDate(value)) {
    return new Date();
  }

  const parts = value.split(".");
  const ts = Date.parse(parts[2] + "-" + parts[1] + "-" + parts[0]);
  return new Date(ts);
};

export { createKey, splitKey, validDate, dateFromString };
