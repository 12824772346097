import { useQuery } from "react-query";
import { fetchInventoryTotalReport, fetchInventory } from "./queries";

const useInventoryTotalQuery = (customerID) => {
  return useQuery(["products", customerID, "report"], () =>
    fetchInventoryTotalReport(customerID)
  );
};

const useInventoryQuery = (customer_id, pagination, sortBy) => {
  let sort = JSON.stringify(sortBy);
  let p = { ...pagination, sort };
  p = customer_id === "0" ? p : { ...p, customer_id };
  return useQuery(["products", customer_id, sort, pagination], () =>
    fetchInventory(p)
  );
};

export { useInventoryTotalQuery, useInventoryQuery };
