import { api } from "../../ApiClient";

export const fetchSupplier = async (supplierID) => {
  return await api.get(`/suppliers/${supplierID}`);
};
export const fetchSuppliers = async () => {
  return await api.get("/suppliers");
};

export const createSupplier = async (supplier) => {
  return await api.post("/suppliers", supplier);
};

export const updateSupplier = async (supplierID, data) => {
  return await api.put(`/suppliers/${supplierID}`, data);
};
