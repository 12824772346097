import { useRef } from "react";
import PrintModalComponent from "../layout/PrintModalComponent";
import RawMaterialesList from "./rawmaterial/RawMaterialList";
import { StickyLabelListPrint } from "./StickyLabelList";
import { usePrintLabel } from "./hooks";

type Props = {
  show: boolean;
  cancel: () => void;
  batch_id: number;
  batch_code: string;
};

const PrintLabels = ({ show, cancel, batch_code, batch_id }: Props) => {
  const componentRef = useRef(null);
  const printLabel = usePrintLabel({
    content: () => componentRef.current,
    onAfterPrint: () => cancel(),
  });

  return (
    <PrintModalComponent
      show={show}
      handlePrint={printLabel}
      handleCancel={cancel}
    >
      <RawMaterialesList id={batch_id}>
        {(data: any[]) => (
          <StickyLabelListPrint
            ref={componentRef}
            barcodeValue={batch_code}
            rawmaterials={data}
          />
        )}
      </RawMaterialesList>
    </PrintModalComponent>
  );
};

export default PrintLabels;
