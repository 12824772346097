import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";

const RestrictedNav = ({ roles, to, name }) => {
  const { user } = useUser();
  if (user === undefined || user === null) {
    return null;
  }

  const isAllowed = (userRole) => {
    if (userRole === "admin") {
      return true;
    }
    return roles.find((r) => r === userRole);
  };

  if (!isAllowed(user.role)) {
    return null;
  }

  return (
    <Nav.Item>
      <Nav.Link as={NavLink} to={to}>
        {name}
      </Nav.Link>
    </Nav.Item>
  );
};

export default RestrictedNav;
