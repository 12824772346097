import { Container, Button } from "react-bootstrap";
import Delete from "../../icons/Delete";

export type PartType = {
  id: string;
  name: string;
  weight: number;
};

export type Props = {
  part: PartType;
  onDeletePart: (id: string) => void;
};

const Part = ({ part: { id, name, weight }, onDeletePart }: Props) => {
  return (
    <Container
      className={[
        "d-flex justify-content-between align-items-center",
        "py-1",
        "Butcher-Part",
      ].join(" ")}
    >
      <div style={{ display: "inline-block" }}>
        <DeletePart handleRemove={() => onDeletePart(id)} />
        <span className="fw-bold">{name}</span>
      </div>
      <span>{weight.toFixed(2)}</span>
    </Container>
  );
};

const DeletePart = ({ handleRemove }: { handleRemove: () => void }) => {
  return (
    <Button
      style={{ marginLeft: "6px" }}
      variant="secondary"
      className="btn-sm"
      onClick={handleRemove}
    >
      <Delete />
    </Button>
  );
};

export default Part;
