import { useQuery } from "react-query";
import DynamicSelect from "../form/DynamicSelect";
import { fetchCustomers } from "../customer/queries";

function CustomerSelect({ name }) {
  let options = [{ id: "", name: "בחר" }];
  //for
  const { isLoading, isError, isSuccess, data } = useQuery(
    ["customers", { active: 1 }],

    () => fetchCustomers({ active: 1 })
  );

  if (isLoading || isError) {
    //do nothing
  }

  if (isSuccess) {
    options = [...options, ...data.body];
  }

  return <DynamicSelect name={name} options={options} />;
}

export default CustomerSelect;
