import { api } from "../../ApiClient";

export const fetchInventoryTotalReport = async (customerID) => {
  return await api.get(`/products/${customerID}/report`);
};

export const downloadInventoryTotalReport = async (customerID, filename) => {
  return await api.fileDownload(`/products/${customerID}/report`, filename, {
    file: 1,
  });
};

export const fetchInventory = async (params) => {
  return await api.get(`/products`, { ...params, inventory: 1 });
};
