import {
  Formik,
  Form,
  ErrorMessage,
  FormikBag,
  Field,
  FieldInputProps,
} from "formik";
import { default as BootForm } from "react-bootstrap/Form";
import * as Yup from "yup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import SubmitCancel from "../../form/SubmitCancel";
import CounterInput from "react-counter-input";
import { Stack } from "react-bootstrap";

type Props = {
  handleSubmit: (values: any, conrol: any) => void;
  handleCancel: () => void;
  maxWeight: number | undefined;
  parts: any[];
};

const PartSelectForm = ({
  handleSubmit,
  handleCancel,
  maxWeight,
  parts,
}: Props) => {
  const perRow = 3;
  const rows: any[] = [];
  for (let i = 0; i < parts.length; i += perRow) {
    const row = parts.slice(i, i + perRow);
    rows.push(row);
  }

  const label =
    maxWeight === undefined ? "משקל:" : `משקל (מקס', ${maxWeight.toFixed(2)}):`;
  const isStrict = maxWeight !== undefined;

  return (
    <Formik
      initialValues={{
        weight: "",
        part_id: -1,
        no_of_parts: 1,
      }}
      onSubmit={handleSubmit}
      validationSchema={partSchema(maxWeight)}
    >
      {({ isSubmitting }) => (
        <Form>
          <BootForm.Group>
            <Container>
              {rows.map((row: any, index) => (
                <Row key={index}>
                  {row.map((part: any) => (
                    <Col sm={4} key={part.id}>
                      <Field
                        as={BootForm.Check}
                        value={String(part.id)}
                        label={part.name}
                        name="part_id"
                        type="radio"
                        id={String(part.id)}
                      />
                    </Col>
                  ))}
                </Row>
              ))}
              <ErrorMessage
                name="part_id"
                className="text-danger"
                component="div"
              />
            </Container>
          </BootForm.Group>

          <Stack direction="horizontal" gap={4} className="align-items-start">
            <BootForm.Group className="mt-3">
              <BootForm.Label htmlFor="weight">{label}</BootForm.Label>
              <Field
                name="weight"
                className="form-control"
                style={{ width: "150px" }}
                type="text"
              />
              <ErrorMessage
                name="weight"
                className="text-danger"
                component="div"
              />
            </BootForm.Group>
            {!isStrict && (
              <BootForm.Group className="mt-3">
                <BootForm.Label htmlFor="no_of_parts">
                  מספר חלקים:
                </BootForm.Label>
                <Field name="no_of_parts">
                  {({
                    field,
                    form,
                  }: {
                    field: FieldInputProps<any>;
                    form: FormikBag<any, any>;
                  }) => (
                    <CounterInput
                      min={1}
                      inputStyle={{ fontWeight: 700 }}
                      count={field.value}
                      max={999}
                      onCountChange={(count: number) => {
                        form.setFieldValue("no_of_parts", count);
                      }}
                    />
                  )}
                </Field>
              </BootForm.Group>
            )}
          </Stack>

          <BootForm.Group>
            <BootForm.Label></BootForm.Label>
            <SubmitCancel
              handleCancel={handleCancel}
              isSubmitting={isSubmitting}
            />
          </BootForm.Group>
        </Form>
      )}
    </Formik>
  );
};

const partSchema = (maxWeight: number | undefined) => {
  //check weight fun
  let wValid = (Yup as any).number().positive().weight().required("*");

  if (maxWeight !== undefined) {
    wValid = (Yup as any)
      .number()
      .max(maxWeight)
      .positive()
      .weight()
      .required("*");
  }

  return Yup.object({
    weight: wValid,
    part_id: Yup.number().positive("בחר חלק").required("*"),
  });
};

export default PartSelectForm;
