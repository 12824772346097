import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { confirmable, createConfirmation } from "react-confirm";
import CancelButton from "./CancelButton";
import OkButton from "./OkButton";

const Confirmation = ({
  title = "אשוּר",
  confirmation,
  show,
  proceed,
  enableEscape = true,
}) => {
  return (
    <div className="static-modal">
      <Modal
        animation={false}
        show={show}
        onHide={() => proceed(false)}
        backdrop={enableEscape ? true : "static"}
        keyboard={enableEscape}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{confirmation}</Modal.Body>
        <Modal.Footer>
          <OkButton handleOk={() => proceed(true)} />
          <CancelButton handleCancel={() => proceed(false)} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Confirmation.propTypes = {
  title: PropTypes.string,
  confirmation: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
};

export function confirm(confirmation, options = {}) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    ...options,
  });
}
