import Badge from "react-bootstrap/Badge";

type Props = { code: string };

const RawPartPartBadge = ({ code }: Props) => (
  <Badge style={{ marginRight: "4px" }} bg="secondary">
    {code}
  </Badge>
);

export default RawPartPartBadge;
