import axios from "axios";

const timeout = 5000; //3 seconds
//
class ApiClient {
  constructor() {
    this.axiosClient = axios.create({
      baseURL: window.__REACT_APP_BASE_API_URL__,
      timeout: timeout,
    });
  }

  async request(options) {
    let response = null;
    try {
      response = await this.axiosClient.request({
        url: options.url,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          ...options.headers,
        },
        method: options.method,
        params: options.params,
        data: options.data,
        responseType: options.responseType,
      });
    } catch (err) {
      throw Error(err.request.responseURL, {
        cause: err,
      });
    }

    return {
      status: response.status,
      body: response.data,
    };
  }

  // https://bobbyhadz.com/blog/download-file-using-axios
  async fileDownload(url, fileName, params, options) {
    const response = await this.request({
      method: "get",
      url,
      params: params,
      responseType: "blob",
      ...options,
    });

    const href = window.URL.createObjectURL(response.body);

    const anchorElement = document.createElement("a");

    anchorElement.href = href;
    anchorElement.download = fileName;

    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  }

  //params: must be a plain object or a URLSearchParams object
  async get(url, params, options) {
    return this.request({ method: "get", url, params: params, ...options });
  }

  async post(url, data, options) {
    return this.request({ method: "post", url, data, ...options });
  }

  async delete(url, options) {
    return this.request({ method: "delete", url, ...options });
  }

  async put(url, data, options) {
    return this.request({ method: "put", url, data, ...options });
  }

  async patch(url, data, options) {
    return this.request({ method: "patch", url, data, ...options });
  }

  async logout() {
    localStorage.removeItem("accessToken");
  }

  // login w/ user's email/password
  async login(email, password) {
    let response = await this.post("/auth/login", { email, password });
    localStorage.setItem("accessToken", response.body.token);
    return response;
  }

  isAuthenticated() {
    return localStorage.getItem("accessToken") !== null;
  }
}

export default ApiClient;

const api = new ApiClient();
export { api };
