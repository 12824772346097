import CustomerSelect from "../../customer/CustomerSelect";
import ActionBar from "../../ActionBar";

type Props = {
  setCustomerID: (e: any) => void;
  customer_id: string;
};

const AvailableHeader = ({ customer_id, setCustomerID }: Props) => {
  return (
    <ActionBar>
      לקוח:
      <CustomerSelect
        onChange={(e) => {
          const t = e.target;
          setCustomerID({
            value: t.value,
            name: t.options[t.options.selectedIndex].innerHTML,
          });
        }}
        value={customer_id}
      />
    </ActionBar>
  );
};

export default AvailableHeader;
