import Container from "react-bootstrap/Container";
import Title from "./Title";
import { StrictModeDroppable as Droppable } from "../../dragndrop/StrictModeDroppable";
import BatchRawMaterialList from "./BatchRawMaterialList";

const Batch = ({ batch, rawmaterials }) => {
  return (
    <Container
      style={{ marginLeft: 0, marginRight: 0 }}
      className="Batch"
      id={batch.id}
    >
      <Title title={"אצווה " + batch.name} />
      <Droppable droppableId={batch.id}>
        {(provided, snapshot) => (
          <BatchRawMaterialList
            list={rawmaterials}
            provided={provided}
            snapshot={snapshot}
          ></BatchRawMaterialList>
        )}
      </Droppable>
    </Container>
  );
};

export default Batch;
