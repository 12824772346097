import { forwardRef } from "react";
import StickyLabel from "./StickyLabel";

type Props = {
  rawmaterials: any[];
  barcodeValue: string;
};

const StickyLabelList = ({ barcodeValue, rawmaterials }: Props) => {
  return (
    <>
      {rawmaterials.map((raw: any) => {
        return (
          <StickyLabel
            key={raw.id}
            barcodeValue={barcodeValue}
            barcodeID={"id" + barcodeValue}
            slaughter_id={raw.slaughter_id}
            supplier_id={raw.supplier_id}
            part_id={raw.part_id}
            kind_id={raw.kind_id}
            comment={raw.comment}
          />
        );
      })}
    </>
  );
};

export default StickyLabelList;

export const StickyLabelListPrint = forwardRef((props: Props, ref) => {
  return (
    // @ts-ignore
    <div ref={ref}>
      <StickyLabelList {...props} />
    </div>
  );
});
