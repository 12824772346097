import { useQuery } from "react-query";
import { fetchProductLog } from "./queries";

const useProductLogQuery = (customer_id, pagination, sortBy) => {
  let sort = JSON.stringify(sortBy);
  let p = { ...pagination, sort };
  p = customer_id === "0" ? p : { ...p, customer_id };
  return useQuery(["products", customer_id, sort, pagination], () =>
    fetchProductLog(p)
  );
};

export { useProductLogQuery };
