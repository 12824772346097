import Button from "react-bootstrap/Button";
import Edit from "../icons/Edit";

type props = {
  onEdit: () => void;
};

const EditEntity = ({ onEdit }: props) => {
  return (
    <Button variant="light" onClick={onEdit}>
      <Edit />
    </Button>
  );
};

export default EditEntity;
