import Table from "react-bootstrap/Table";
import SupplierRow from "./SupplierRow";

const SuppliersTable = ({ suppliers, handleEdit }) => {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>שם</th>
          <th>פעיל</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {suppliers.map((entity) => {
          return (
            <SupplierRow
              key={entity.id}
              entity={entity}
              handleEdit={handleEdit}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default SuppliersTable;
