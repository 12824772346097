import Barcode from "./Barcode";
import Vertical from "../layout/Vertical";
import { Container } from "react-bootstrap";
import SupplierCell from "./rawmaterial/SupplierCell";
import RawMaterialCell from "../part/RawPartCell";
import CustomerCell from "./rawmaterial/CustomerCell";

type Props = {
  barcodeValue: string;
  barcodeID: string;
  slaughter_id: string;
  supplier_id: number;
  part_id: number;
  kind_id: number;
  comment: string | null;
};

const StickyLabel = ({
  barcodeID,
  barcodeValue,
  slaughter_id,
  supplier_id,
  part_id,
  kind_id,
  comment,
}: Props) => {
  let clz = "text-center mt-1";
  let aComment = "";
  if (comment) {
    clz = clz.replace("mt-1", "");
    aComment = comment;
  }
  return (
    <div className="StickyLabel">
      <Vertical>
        <Container className={clz}>
          <SupplierCell id={supplier_id} /> / {slaughter_id}
          <br />
          <RawMaterialCell id={part_id} /> / <CustomerCell id={kind_id} />
          <br />
          {aComment}
        </Container>
        <Container className="text-center">
          <Barcode value={barcodeValue} id={barcodeID} />
        </Container>
      </Vertical>
    </div>
  );
};

export default StickyLabel;
