import { useBatchesQuery } from "./batch/hooks";
import Vertical from "./layout/Vertical";
import { Spinner } from "react-bootstrap";
import BatchesTable from "./batch/BatchesTable";
import { Paginator } from "./pagination/Paginator";
import useOnPage from "./pagination/hooks";

const Batches = () => {
  const [page, handlePrev, handleNext] = useOnPage(1);

  //for listing batches
  const { isLoading, isError, isSuccess, data } = useBatchesQuery({
    page: page,
    pageSize: Paginator.defaultPageSize,
  });

  let content = null;
  if (isLoading) {
    content = <Spinner animation="border" />;
  }

  if (isError) {
    content = <p>לא ניתן להוריד את האצוות </p>;
  }

  if (isSuccess) {
    const pg = data.body.meta.pagination;
    const onPage = Paginator.onPage(pg.page, pg.page_size, pg.total);
    content = (
      <Paginator
        handlePrev={handlePrev}
        handleNext={handleNext}
        onfirst={onPage.first}
        onlast={onPage.last}
      >
        <BatchesTable batches={data.body.data} />
      </Paginator>
    );
  }

  return <Vertical>{content}</Vertical>;
};

export default Batches;
