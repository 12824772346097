import ProductSheet from "../productSheet/ProductSheet";
import { useProductSheetQuery } from "../productSheet/hooks";
import { Spinner } from "react-bootstrap";

type Props = { sheet_id: number };

const LoadProductSheet = ({ sheet_id }: Props) => {
  const { isLoading, isError, isSuccess, data } =
    useProductSheetQuery(sheet_id);

  let content = null;
  if (isLoading) {
    content = <Spinner animation="border" />;
  }

  if (isError) {
    content = <p>לא ניתן להוריד את הליקוט</p>;
  }

  if (isSuccess) {
    const sheet = data.body;
    content = (
      <ProductSheet
        product_list={sheet.products}
        customer_name={sheet.customer_name}
        created_at={sheet.created_at}
      />
    );
  }

  return content;
};

export default LoadProductSheet;
