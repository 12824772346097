import TotalInventoryTable from "./TotalInventoryTable";
import { Spinner } from "react-bootstrap";
import { useInventoryTotalQuery } from "./hooks";

type Props = { customer_id: string };

const TotalReportPanel = ({ customer_id }: Props) => {
  const { isLoading, isError, isSuccess, data } =
    useInventoryTotalQuery(customer_id);

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (isError) {
    return <p>לא ניתן להוריד את דוח</p>;
  }

  if (isSuccess) {
    return (
      <TotalInventoryTable data={data.body.data} meta={data.body.meta.totals} />
    );
  }

  return <></>;
};

export default TotalReportPanel;
