import Table from "react-bootstrap/Table";
import CustomerRow from "./CustomerRow";

const CustomersTable = ({ customers, handleEdit }) => {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>שם</th>
          <th>פעיל</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {customers.map((entity) => {
          return (
            <CustomerRow
              key={entity.id}
              entity={entity}
              handleEdit={handleEdit}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default CustomersTable;
