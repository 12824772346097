import { useQuery } from "react-query";
import { fetchRawPart } from "./queries";
import DisplayAtom from "../DisplayAtom";

type Props = {
  id: number;
};

const RawMaterialCell = ({ id }: Props) => {
  //for listing parts
  const { isLoading, isError, isSuccess, data } = useQuery(
    ["rawparts", id],

    () => fetchRawPart(id)
  );
  return (
    <DisplayAtom
      isLoading={isLoading}
      isError={isError}
      isSuccess={isSuccess}
      atom={data?.body?.name}
    />
  );
};

export default RawMaterialCell;
