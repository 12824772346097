import { useState } from "react";
import { FormikHelpers } from "formik";
import CustomerSelect from "./customer/CustomerSelect";
import Stack from "react-bootstrap/Stack";
import ActionBar from "./productSheet/ActionBar";
import ProductSheetPanel from "./productSheet/ProductSheetPanel";
import useCRUDDialogs from "./crud/hooks";
import ModalContainer from "./layout/ModalContainer";
import CreateForm from "./productSheet/CreateForm";
import { useProductSheetCreateMutation } from "./productSheet/hooks";

type Props = {};

//for creating a new sheet
interface CreateValues {
  customer_id: string;
}

const ProductSheet = ({}: Props) => {
  const [customerID, setCustomerID] = useState(CustomerSelect.ALL_CUSTOMER);
  const { setShowCreate, isCreateShowing, setClose } = useCRUDDialogs();

  const handleCreate = async (
    { customer_id }: CreateValues,
    { setSubmitting, setStatus }: FormikHelpers<CreateValues>
  ) => {
    let newSheet = { customer_id: parseInt(customer_id) };

    //pass sheet and callbacks for error/success handling
    let variables: any = {
      newSheet,
      closeDialog: setClose,
      setSubmitting,
      setStatus,
    };
    mutation.mutate(variables);
  };

  const handleCreateError = (_err: any, variables: any) => {
    variables.setSubmitting(false);
    variables.setStatus({
      message: "לא נשמר, אירעה שגיאה",
      variant: "danger",
    });
  };
  const mutation = useProductSheetCreateMutation(handleCreateError);

  return (
    <>
      <ModalContainer show={isCreateShowing()} title="הוספת דף ליקוט">
        <CreateForm handleSubmit={handleCreate} handleCancel={setClose} />
      </ModalContainer>
      <Stack className="p-0" gap={4}>
        <ActionBar
          {...{
            setCustomerID,
            customer_id: customerID.value,
            onNew: setShowCreate,
          }}
        />
        <ProductSheetPanel customer_id={customerID.value} />
      </Stack>
    </>
  );
};

export default ProductSheet;
