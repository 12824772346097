import Form from "react-bootstrap/Form";
import { Field } from "formik";

/*
 */
function DynamicSelect({ name, options = [] }) {
  return (
    <Field as={Form.Select} name={name}>
      {options.map((op) => {
        return (
          <option key={op.id} value={"" + op.id}>
            {op.name}
          </option>
        );
      })}
    </Field>
  );
}

export default DynamicSelect;
