import equalWeights from "./utils.js";

class RawMaterialMeta {
  rawData = {};
  constructor(id, rawWeight, partsWeight = 0, slaughterId, strictWeight) {
    this.rawData.id = id;
    this.rawData.raw_weight = rawWeight;
    this.rawData.parts_weight = partsWeight;
    this.rawData.slaughter_id = slaughterId;
    this.rawData.strict_weight = strictWeight;
  }
  /*
   * add a part weight: can be positive or negative
   */
  addPartWeight(w) {
    const parts_weight = this.rawData.parts_weight + w;
    if (parts_weight < 0) {
      throw new Error("Total parts weight cannot be less than 0");
    }

    if (this.rawData.strict_weight && parts_weight > this.rawData.raw_weight) {
      throw new Error(
        "Total parts weight cannot be greater than raw material weight"
      );
    }
    //create a new object
    this.rawData = { ...this.rawData, parts_weight };
  }

  /*
   * data as object
   */
  get data() {
    return this.rawData;
  }

  isDone() {
    return (
      !this.rawData.strict_weight ||
      equalWeights(this.rawData.raw_weight, this.rawData.parts_weight)
    );
  }
}

export default RawMaterialMeta;
