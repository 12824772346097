import Badge from "react-bootstrap/Badge";
import SupplierCell from "./SupplierCell";
import RawMaterialCell from "../../part/RawPartCell";
import CustomerCell from "./CustomerCell";
import Number from "../../i18n/Number";
import Actions from "./Actions";
import { RawMaterial } from "./types";

type Props = {
  rawmaterial: RawMaterial;
  index: number;
  isPrint: boolean;
  onPrint: (raw: RawMaterial) => void;
};

const RawMaterialRow = ({
  index,
  rawmaterial,
  onPrint,
  isPrint = false,
}: Props) => {
  return (
    <tr>
      <td>
        <Index index={index} />
      </td>
      <td>{rawmaterial.slaughter_id} </td>
      <td>
        <SupplierCell id={rawmaterial.supplier_id} />
      </td>
      <td>
        <RawMaterialCell id={rawmaterial.part_id} />
      </td>
      {!isPrint && (
        <td>
          <Number value={rawmaterial.temperature} />
        </td>
      )}
      <td>
        <CustomerCell id={rawmaterial.kind_id} />
      </td>
      <td>{rawmaterial.comment}</td>
      <td>{rawmaterial.weight}</td>
      {!isPrint && (
        <td>
          <Actions onPrintLabel={() => onPrint(rawmaterial)} />
        </td>
      )}
    </tr>
  );
};

const Index = ({ index }: { index: number }) => (
  <Badge bg="secondary">{index}</Badge>
);

export default RawMaterialRow;
