import { useQuery } from "react-query";
import { fetchRawMaterialsForBatch } from "../queries";

type Props = {
  id: number;
  children: any;
};

const RawMaterialesList = ({ id, children }: Props) => {
  //rawmaterial
  const { isLoading, isError, isSuccess, data } = useQuery(
    ["batches", id, "rawmaterials"],

    () => fetchRawMaterialsForBatch(id)
  );

  let content = null;
  if (isLoading) {
    content = "טוען...";
  }

  if (isError) {
    content = "שגיאת טעינה";
  }

  if (isSuccess) {
    content = children(data.body);
  }

  return <>{content}</>;
};

export default RawMaterialesList;
