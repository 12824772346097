import { useState } from "react";
import DateLocale from "../DateLocale";
import RawMaterialesList from "./rawmaterial/RawMaterialList";
import RawMaterialTable from "./rawmaterial/RawMaterialTable";
import Actions from "./Actions";
import PrintLabels from "./PrintLabels";
import PrintBatch from "./PrintBatch";
import BatchBadge from "./BatchBadge";

type BatchUser = {
  id: number;
  first: string;
  last: string;
};

type Batch = {
  id: number;
  code: string;
  raw_material_count: number;
  raw_material_weight: number;
  butchered_completed_at: string | null;
  entered_by: BatchUser;
  created_at: string;
};

type Props = {
  batch: Batch;
  onDelete: (batch: Batch) => void;
};

const BatchRow = ({ batch, onDelete }: Props) => {
  const [showRawMaterials, setShowRawMaterials] = useState(false);
  const [isAction, setAction] = useState("none");

  return (
    <>
      {isAction === "label" && (
        <PrintLabels
          show={true}
          cancel={() => setAction("none")}
          batch_id={batch.id}
          batch_code={batch.code}
        />
      )}
      {isAction === "batch" && (
        <PrintBatch
          show={isAction === "batch"}
          cancel={() => setAction("none")}
          batch={batch}
        />
      )}

      <tr>
        <td
          className="BatchIDCell"
          onClick={() => setShowRawMaterials((prev) => !prev)}
        >
          <BatchBadge code={batch.code} />
        </td>
        <td>
          <DateLocale utcDate={batch.created_at} />
        </td>
        <td>
          {batch.entered_by.first} {batch.entered_by.last}
        </td>
        <td>{batch.raw_material_count}</td>
        <td>{batch.raw_material_weight}</td>
        <td>
          {batch.butchered_completed_at !== null ? (
            <DateLocale utcDate={batch.butchered_completed_at} />
          ) : (
            "-"
          )}
        </td>
        <td>
          <Actions
            onPrintLabel={() => setAction("label")}
            onPrintBatch={() => setAction("batch")}
            onDelete={() => onDelete(batch)}
            canDelete={batch.butchered_completed_at === null}
          ></Actions>
        </td>
      </tr>
      {showRawMaterials && (
        <tr>
          <td colSpan={7}>
            <RawMaterialesList id={batch.id}>
              {(data: any) => (
                <RawMaterialTable rawmaterials={data} batch_code={batch.code} />
              )}
            </RawMaterialesList>
          </td>
        </tr>
      )}
    </>
  );
};

export default BatchRow;
