import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import TotalReportPanel from "./TotalReportPanel";
import { downloadInventoryTotalReport } from "./queries";

const generateFileName = (name: string) => {
  const now = new Date();
  return `${name}-${now.getDate()}-${now.getMonth() + 1}-${now.getFullYear()}`;
};

const TotalInventoryTab = ({
  value,
  name,
}: {
  value: string;
  name: string;
}) => {
  return (
    <Container className="p-0">
      <Container className={["d-flex", "flex-row-reverse", "p-0"].join(" ")}>
        <Button
          onClick={() => {
            downloadInventoryTotalReport(value, generateFileName(name));
          }}
        >
          הורדת דוח
        </Button>
      </Container>
      <Container className="px-0 mt-3">
        <TotalReportPanel customer_id={value} />
      </Container>
    </Container>
  );
};

export default TotalInventoryTab;
