import { dateFromString, splitKey } from "./utils";

type RawSelect = {
  //date format mm.dd.yyyy
  slaughtered_at: string;
  slaughter_number: string;
  weight: string;
  temperature: string;
  comment: string;
  kind: string;
  part: string;
  supplier: string;
};

class RawMaterial {
  id: string;
  slaughterId: string;
  slaughteredAt: string;
  weight: number;
  temperature: number;
  comment: string;
  kindName: string;
  kindId: number;
  partName: string;
  partId: number;
  supplierName: string;
  supplierId: number;

  constructor(raw: RawSelect) {
    //generate a temporary id
    this.id = Date.now().toString();

    this.slaughterId = raw.slaughter_number;

    this.slaughteredAt = dateFromString(raw.slaughtered_at).toISOString();
    this.temperature = parseFloat(raw.temperature);
    this.comment = raw.comment.trim();
    this.weight = parseFloat(raw.weight);

    const [kindId, kindName] = splitKey(raw.kind);
    this.kindId = parseInt(kindId);
    this.kindName = kindName;
    const [partId, partName] = splitKey(raw.part);
    this.partId = parseInt(partId);
    this.partName = partName;
    const [supplierId, supplierName] = splitKey(raw.supplier);
    this.supplierId = parseInt(supplierId);
    this.supplierName = supplierName;
  }

  export() {
    return {
      slaughtered_at: this.slaughteredAt,
      slaughter_id: this.slaughterId,
      weight: this.weight,
      temperature: this.temperature,
      comment: this.comment,
      kind_id: this.kindId,
      part_id: this.partId,
      supplier_id: this.supplierId,
    };
  }
}

export default RawMaterial;
