import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { confirmable, createConfirmation } from "react-confirm";
import CancelButton from "./CancelButton";

const Information = ({
  title = "אִשׁוּר",
  information,
  show,
  proceed,
  enableEscape = true,
}) => {
  return (
    <div className="static-modal">
      <Modal
        animation={false}
        show={show}
        onHide={() => proceed(false)}
        backdrop={enableEscape ? true : "static"}
        keyboard={enableEscape}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{information}</Modal.Body>
        <Modal.Footer>
          <CancelButton handleCancel={() => proceed(false)} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

Information.propTypes = {
  title: PropTypes.string,
  information: PropTypes.string,
  show: PropTypes.bool,
  proceed: PropTypes.func, // called when ok button is clicked.
  enableEscape: PropTypes.bool,
};

export function information(information, proceedLabel = "המשך", options = {}) {
  return createConfirmation(confirmable(Information))({
    information,
    proceedLabel,
    ...options,
  });
}
