import { useState } from "react";
import BatchManager from "./batch/manager/BatchManager";
import Panel from "./batch/Panel";
import ModalContainer from "./layout/ModalContainer";
import CreateForm from "./batch/rawmaterial/CreateForm";
import { useBatchSaveMutation } from "./batch/hooks";
import { useFlash } from "../contexts/FlashProvider";

const BatchBuilder = () => {
  const [batchMgr] = useState(new BatchManager());
  const [data, setData] = useState(batchMgr.data);
  const [showDialog, setShowDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const flash = useFlash();

  const handleNewBatch = async () => {
    await batchMgr.addBatch();
    setData(batchMgr.data);
  };

  const handleBatchReset = () => {
    batchMgr.resetBatches();
    setData(batchMgr.data);
  };

  const onBatchCreated = (_data, variables) => {
    setIsSubmitting(false);
    let flashMsg =
      variables.batches.length === 1 ? "האצווה נשמרה" : "האצוות נשמרו";
    flash(flashMsg, "success");
    batchMgr.reset();
    setData(batchMgr.data);
  };

  const onBatchCreatedError = (__error) => {
    setIsSubmitting(false);
    flash("האצוות לא נשמרו", "danger");
  };

  //create batches
  const create = useBatchSaveMutation({
    onSuccess: onBatchCreated,
    onError: onBatchCreatedError,
  });

  //save to the server...
  const handleBatchSave = () => {
    const toSave = [];
    batchMgr.batchesList.forEach((id) => {
      toSave.push(batchMgr.export(id));
    });
    //reverse the order so the lowest code is saved first
    toSave.reverse();
    create.mutate({ batches: toSave });
    setIsSubmitting(true);
  };

  const handleRemoveRawMaterial = (id) => {
    batchMgr.removeRawMaterial(id);
    setData(batchMgr.data);
  };

  const handleNewRawMaterial = (values, { setSubmitting }) => {
    batchMgr.addRawMaterial(values);
    setSubmitting(false);
    setShowDialog(false);
    setData(batchMgr.data);
  };

  const createNewRawMaterial = () => {
    setShowDialog(true);
  };

  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }

    const rawMaterialMoved = (destination, source) => {
      return destination.droppableId === source.droppableId;
    };

    if (rawMaterialMoved(destination, source)) {
      return;
    }

    batchMgr.rawMaterialMoved(source, destination, draggableId);
    setData(batchMgr.data);
  };

  return (
    <>
      <ModalContainer show={showDialog} title="הוספת חומר גלם">
        <CreateForm
          handleSubmit={handleNewRawMaterial}
          handleCancel={() => setShowDialog(false)}
        />
      </ModalContainer>
      <Panel
        data={data}
        isSubmitting={isSubmitting}
        handleNewBatch={handleNewBatch}
        handleBatchReset={handleBatchReset}
        handleNewRawMaterial={createNewRawMaterial}
        handleRemoveRawMaterial={handleRemoveRawMaterial}
        handleDragEnd={handleDragEnd}
        handleBatchSave={handleBatchSave}
        control={{
          hasBatches: batchMgr.hasBatches(),
          canSave: batchMgr.canSave(),
        }}
        batches={batchMgr}
      />
    </>
  );
};

export default BatchBuilder;
