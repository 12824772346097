import { Stack } from "react-bootstrap";
import ActionBar from "./ActionBar";
import AddedProductPanel from "./AddedProductPanel";
import AvailableProductPanel from "./AvailableProductPanel";
import { ProductSheet } from "../types";
import ModalContainer from "../../layout/ModalContainer";

type Props = {
  sheet: ProductSheet;
  onClose: () => void;
  onSave: () => void;
};

const CollectProduct = ({ sheet, onClose, onSave }: Props) => {
  return (
    <ModalContainer
      show={true}
      title={`דף ליקוט ל${sheet.customer_name}`}
      modalSize="lg"
      className="CollectProductModal"
      footer={<ActionBar onSave={onSave} onClose={onClose} />}
    >
      <Stack gap={3} className="CollectProduct">
        <AvailableProductPanel
          sheet_id={sheet.id}
          customer_id={sheet.customer_id}
        />
        <AddedProductPanel sheet={sheet} />
      </Stack>
    </ModalContainer>
  );
};

export default CollectProduct;
