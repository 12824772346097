import { useQuery } from "react-query";
import { Spinner, Container } from "react-bootstrap";
import PartSelectForm from "./PartSelectForm";
import { fetchRawPartParts } from "../../part/queries";
import CancelButton from "../../form/CancelButton";

type Props = {
  rawId: number;
  handleSubmit: (values: any, count: number, conrol: any) => void;
  handleCancel: () => void;
  maxWeight: number | undefined;
};

const PartSelector = ({
  rawId,
  handleSubmit,
  handleCancel,
  maxWeight,
}: Props) => {
  //Map of parts
  const partMap = new Map<number, string>();

  const wrapHandleSubmit = (values: any, control: any) => {
    const part_id = parseInt(values.part_id);
    const weight = parseFloat(values.weight);
    const name = partMap.get(part_id);
    let v = { part_id, name, weight };
    handleSubmit(v, values.no_of_parts, control);
  };
  //fetch parts of raw part
  const { isLoading, isError, isSuccess, data } = useQuery(
    ["rawparts", rawId, "parts"],

    () => fetchRawPartParts(rawId)
  );

  let content = null;
  if (isLoading) {
    content = <Spinner animation="border" />;
  }

  if (isError) {
    content = (
      <Container>
        <p>לא ניתן להוריד את רשימת החלקים</p>
        <CancelButton handleCancel={handleCancel} isSubmitting={false} />
      </Container>
    );
  }

  if (isSuccess) {
    //add parts to the map
    data.body.forEach((p: any) => partMap.set(p.id, p.name));
    content = (
      <PartSelectForm
        handleSubmit={wrapHandleSubmit}
        handleCancel={handleCancel}
        maxWeight={maxWeight}
        parts={data.body}
      />
    );
  }

  return <>{content}</>;
};

export default PartSelector;
