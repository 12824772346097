import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { SortingState } from "@tanstack/react-table";
import ProductSheetTable from "./ProductSheetTable";
import useOnPage from "../pagination/hooks";
import { useProductSheetsQuery } from "./hooks";
import { Paginator } from "../pagination/Paginator";

type Props = {
  customer_id: string;
  onAction: (action: string, sheet: any) => void;
};
const sortingDefault: SortingState = [];

const ProductSheetTablePanel = ({ customer_id, onAction }: Props) => {
  const [page, handlePrev, handleNext] = useOnPage(1);
  const [sorting, setSorting] = useState<SortingState>(sortingDefault);

  const { isLoading, isError, isSuccess, data } = useProductSheetsQuery(
    customer_id,
    { page, pageSize: Paginator.defaultPageSize },
    sorting
  );

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (isError) {
    return <p>לא ניתן להוריד את דוח</p>;
  }

  if (isSuccess) {
    const sheets = data.body.data;

    const pg = data.body.meta.pagination;
    const onPage = Paginator.onPage(pg.page, pg.page_size, pg.total);
    return (
      <Paginator
        handlePrev={handlePrev}
        handleNext={handleNext}
        onfirst={onPage.first}
        onlast={onPage.last}
      >
        <ProductSheetTable
          {...{ data: sheets, sorting, onSorting: setSorting }}
          onAction={onAction}
        />
      </Paginator>
    );
  }
};

export default ProductSheetTablePanel;
