import AddedProductTable from "./AddedProductTable";
import { ProductSheet } from "../types";
import LoadProductSheetProducts from "../LoadProductSheetProducts";
import { useProductSheetProductDeleteMutation } from "../hooks";

type Props = {
  sheet: ProductSheet;
};

const AddedProductPanel = ({ sheet }: Props) => {
  const deleteMutation = useProductSheetProductDeleteMutation(() => {
    /*do nothing*/
  });

  return (
    <LoadProductSheetProducts sheet={sheet}>
      {(sheet: any, data: any[]) => {
        //onAddedProductChange(data?.length);
        return (
          <div>
            <h5 className="p-1">{`מוצרים בדף (${data?.length}):`}</h5>
            <AddedProductTable
              product_list={data}
              onRemove={(p) => {
                deleteMutation.mutate({
                  sheetID: sheet.id,
                  productID: p.id,
                } as any);
              }}
            />
          </div>
        );
      }}
    </LoadProductSheetProducts>
  );
};

export default AddedProductPanel;
