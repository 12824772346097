import Body from "../components/Body";
import Inventory from "../components/Inventory";

const InventoryPage = () => {
  return (
    <Body>
      <h2>מלאי</h2>
      <Inventory />
    </Body>
  );
};

export default InventoryPage;
