import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Vertical from "./layout/Vertical";
import ActionBar from "./ActionBar";
import NewEntity from "./form/NewEntity";
import { Spinner } from "react-bootstrap";
import PartsTable from "./part/PartsTable";
import ModalContainer from "./layout/ModalContainer";
import CreateForm from "./part/CreateForm";
import UpdateForm from "./part/UpdateForm";
import { fetchParts, createPart, updatePart } from "./part/queries";
import useCRUDDialogs from "./crud/hooks";

const Parts = () => {
  const [selectedEntity, setSelectedEntity] = useState(null);
  //for refetching users and updating the table after delete/create/update
  const queryClient = useQueryClient();
  const {
    setShowCreate,
    isCreateShowing,
    setShowUpdate,
    isUpdateShowing,
    setClose,
  } = useCRUDDialogs();

  const closeDialog = () => {
    setClose();
  };

  const handleSaveError = (_err, variables) => {
    variables.setSubmitting(false);
    variables.setStatus({
      message: "החלק לא נשמר, אירעה שגיאה",
      variant: "danger",
    });
  };

  const openUpdateDialog = (entity) => {
    setSelectedEntity(entity);
    setShowUpdate();
  };

  //for creating a part
  const mutation = useMutation({
    mutationFn: (variables) => createPart(variables.newPart),
    onSuccess: (_data, variables) => {
      variables.closeDialog();
      queryClient.invalidateQueries({ queryKey: ["parts"] });
    },
    onError: handleSaveError,
  });

  const handleCreate = async (
    { expires_in, name, active },
    { setSubmitting, setStatus }
  ) => {
    active = /true/.test(active);
    expires_in = parseInt(expires_in);
    let newPart = { expires_in, name, active };

    //pass user and callbacks for error/success handling
    let variables = { newPart, closeDialog, setSubmitting, setStatus };
    mutation.mutate(variables);
  };

  //for updating a part
  const updateMutation = useMutation({
    mutationFn: (variables) => {
      variables.part.active = /true/.test(variables.part.active);
      return updatePart(variables.part.id, variables.part);
    },

    onSuccess: (_data, variables) => {
      variables.closeDialog();
      queryClient.invalidateQueries({ queryKey: ["parts"] });
    },
    onError: handleSaveError,
  });

  const handleUpdate = async (
    { expires_in, name, active },
    { setSubmitting, setStatus }
  ) => {
    let id = selectedEntity.id;
    expires_in = parseInt(expires_in);
    let part = { id, name, active, expires_in };

    //pass part and callbacks for error/success handling
    let variables = { part, closeDialog, setSubmitting, setStatus };
    updateMutation.mutate(variables);
  };

  //for listing parts
  const { isLoading, isError, isSuccess, data } = useQuery(
    ["parts"],

    () => fetchParts()
  );

  let content = null;
  if (isLoading) {
    content = <Spinner animation="border" />;
  }

  if (isError) {
    content = <p>לא ניתן להוריד את רשימת החלקים</p>;
  }

  if (isSuccess) {
    content = <PartsTable parts={data.body} handleEdit={openUpdateDialog} />;
  }

  return (
    <Vertical>
      <ActionBar>
        <NewEntity onNew={setShowCreate} />
      </ActionBar>
      <ModalContainer show={isCreateShowing()} title="הוספת חלק">
        <CreateForm handleSubmit={handleCreate} handleCancel={setClose} />
      </ModalContainer>
      <ModalContainer show={isUpdateShowing()} title="עריכה">
        <UpdateForm
          initialValues={selectedEntity}
          handleSubmit={handleUpdate}
          handleCancel={setClose}
        />
      </ModalContainer>
      {content}
    </Vertical>
  );
};

export default Parts;
