import { useState, useEffect, createContext, useContext } from "react";
import { api } from "../ApiClient";

const UserContext = createContext();

const ADMIN_ROLE = "admin";

export default function UserProvider({ children }) {
  const [user, setUser] = useState();

  useEffect(() => {
    (async () => {
      if (!api.isAuthenticated()) {
        setUser(null);
        return;
      }
      try {
        const response = await api.get("/me");
        setUser(response.body);
      } catch (err) {
        setUser(null);
      }
    })();
  }, []);

  const login = async (email, password) => {
    await api.login(email, password);
    const response = await api.get("/me");
    setUser(response.body);
  };

  const logout = async () => {
    await api.logout();
    //force reload. clears all current 'pages';updates for code
    window.location.reload(true);
    setUser(null);
  };

  //if we dont have a use return false
  const isAdmin = () => {
    if (user === undefined || user === null) {
      return false;
    }

    return user.role === ADMIN_ROLE;
  };

  return (
    <UserContext.Provider value={{ logout, login, user, setUser, isAdmin }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
