import Form from "react-bootstrap/Form";
import { useBatchesQuery } from "../hooks";

function BatchSelect({ selected, onChange, disabled }) {
  let options = [{ id: "", name: "בחר" }];
  //for
  const { isLoading, isError, isSuccess, data, remove } = useBatchesQuery({
    processing: "1",
    page: 1,
    pageSize: 150,
  });

  if (isLoading || isError) {
    //do nothing
  }

  if (isSuccess) {
    const opt = data.body.data.map((e) => {
      return { id: e.id, name: e.code };
    });

    options = [...options, ...opt];
  }

  return (
    <Select
      selected={selected}
      options={options}
      onChange={(e) => {
        //the server data might change; force remove to refetch
        remove();
        onChange(e);
      }}
      disabled={disabled}
    />
  );
}

export default BatchSelect;

/*
 */
function Select({ selected, onChange, options = [], disabled }) {
  return (
    <Form.Select onChange={onChange} value={selected} disabled={disabled}>
      {options.map((op) => {
        return (
          <option key={op.id} value={"" + op.id}>
            {op.name}
          </option>
        );
      })}
    </Form.Select>
  );
}
