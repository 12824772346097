import Check from "../icons/Check";
import XCircle from "../icons/XCircle";
import EditEntity from "../form/EditEntity";

const CustomerRow = ({ entity, handleEdit }) => {
  return (
    <tr>
      <td>{entity.name}</td>
      <td>{entity.active ? <Check /> : <XCircle />}</td>
      <td style={{ textAlign: "left" }}>
        <EditEntity onEdit={() => handleEdit(entity)} />
      </td>
    </tr>
  );
};

export default CustomerRow;
