import { useQuery } from "react-query";
import DynamicSelect from "../../form/DynamicSelect";
import { fetchCustomers } from "../../customer/queries";
import { createKey } from "./utils";

function CustomerSelect({ name }) {
  let options = [{ id: "", name: "בחר" }];
  //for
  const { isLoading, isError, isSuccess, data } = useQuery(
    ["customers"],

    () => fetchCustomers()
  );

  if (isLoading || isError) {
    //do nothing
  }

  if (isSuccess) {
    const active = data.body.filter((v) => v.active);
    const opt = active.map((e) => {
      return { id: createKey(e), name: e.name };
    });
    options = [...options, ...opt];
  }

  return <DynamicSelect name={name} options={options} />;
}

export default CustomerSelect;
