import Form from "react-bootstrap/Form";
import { Field } from "formik";

function ActiveNoActiveSelect({ name, trueName = "כן", falseName = "לא" }) {
  return (
    <Field as={Form.Select} name={name}>
      <option value="true">{trueName}</option>
      <option value="false">{falseName}</option>
    </Field>
  );
}

export default ActiveNoActiveSelect;
