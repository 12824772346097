import Body from "../components/Body";
import RawParts from "../components/RawParts";

const PartsPage = () => {
  return (
    <Body>
      <h2>חלקים גולמיים</h2>
      <RawParts />
    </Body>
  );
};

export default PartsPage;
