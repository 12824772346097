import Body from "../components/Body";
import ProductLog from "../components/ProductLog";

const ProductionLogPage = () => {
  return (
    <Body>
      <h2>יומן ייצור</h2>
      <ProductLog />
    </Body>
  );
};

export default ProductionLogPage;
