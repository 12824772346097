import { Container } from "react-bootstrap";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintModalComponent from "../layout/PrintModalComponent";

type Props = {
  show: boolean;
  cancel: () => void;
  children: any;
};

const PrintComponent = ({ show, cancel, children }: Props) => {
  const componentRef = useRef(null);
  const printComponent = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => cancel(),
    documentTitle: "",
    pageStyle: `
        body {
           direction: rtl;
        }`,
  });

  return (
    <PrintModalComponent
      show={show}
      handlePrint={printComponent}
      handleCancel={cancel}
    >
      <Container ref={componentRef}>{children}</Container>
    </PrintModalComponent>
  );
};

export default PrintComponent;
