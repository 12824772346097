import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  upsertProcessingForBatch,
  createBatch,
  fetchBatches,
  fetchProcessingForBatch,
  deleteBatch,
} from "./queries";
import { PaginateParams } from "../pagination/Paginator";
import { useReactToPrint } from "react-to-print";

interface QueryParams extends PaginateParams {
  processing: string;
}

const useBatchProcessingQuery = (id: number, enabled: boolean) => {
  return useQuery(
    ["batches", id, "processing"],
    () => fetchProcessingForBatch(id),
    {
      enabled: enabled,
      refetchOnWindowFocus: false,
    }
  );
};

const useBatchesQuery = (params: QueryParams) => {
  return useQuery(["batches", params], () => fetchBatches(params));
};

const useBatchSaveMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (data: any, variables: any) => void;
  onError: () => void;
}) => {
  const mutation = useMutation({ mutationFn: createBatch });
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ batches }: { batches: any }) => {
      return await mutation.mutate({ batches });
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["batches"] });
      onSuccess(data, variables);
    },
    onError: onError,
  });
};

//for saving temporary butcher data
const useBatchProcessingSaveMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (variables: any) =>
      upsertProcessingForBatch(variables.id, variables.data),
    onSuccess: (_dat, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["batches", variables.id, "processing"],
      });

      onSuccess();
    },
    onError: onError,
  });
};

type UsePrintLabelProps = {
  content: () => any;
  onAfterPrint: () => void;
};

const usePrintLabel = ({ content, onAfterPrint }: UsePrintLabelProps) => {
  return useReactToPrint({
    content: content,
    onAfterPrint: onAfterPrint,
    documentTitle: "",
    pageStyle: `
       @page {
        size: 6cm 6cm;
        margin: 0 auto;
      }
    `,
  });
};

type UseDeleteBatchProps = {
  onError: () => void;
};

//for deleting a
const useDeleteBatchMutation = ({ onError }: UseDeleteBatchProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userId: string | number) => deleteBatch(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["batches"] });
    },
    onError: onError,
  });
};

export {
  useBatchProcessingSaveMutation,
  useBatchSaveMutation,
  useBatchesQuery,
  useBatchProcessingQuery,
  usePrintLabel,
  useDeleteBatchMutation,
};
