import Body from "../components/Body";
import Suppliers from "../components/Suppliers";

const SuppliersPage = () => {
  return (
    <Body>
      <h2>ספקים</h2>
      <Suppliers />
    </Body>
  );
};

export default SuppliersPage;
