import { useState } from "react";
import Stack from "react-bootstrap/Stack";
import CustomerSelect from "./customer/CustomerSelect";
import ProductLogPanel from "./productLog/ProductLogPanel";
import ActionBar from "./ActionBar";
import PrintComponent from "./print/PrintComponent";
import LoadProductSheet from "./productLog/LoadProductSheet";

type Props = {};

const ProductLog = ({}: Props) => {
  const [customerID, setCustomerID] = useState(CustomerSelect.ALL_CUSTOMER);
  const [productSheetID, setProductSheetID] = useState<number | undefined>();

  const isPrintShowing = () => productSheetID !== undefined;

  return (
    <>
      <Stack className="p-0" gap={4}>
        <ActionBar>
          לקוח:
          <CustomerSelect
            onChange={(e) => {
              const t = e.target;
              setCustomerID({
                value: t.value,
                name: t.options[t.options.selectedIndex].innerHTML,
              });
            }}
            value={customerID.value}
          />
        </ActionBar>
        <ProductLogPanel
          key={customerID.value}
          customer_id={customerID.value}
          onPrintProductSheet={(id: number) => setProductSheetID(id)}
        />
      </Stack>
      {productSheetID !== undefined && (
        <PrintComponent
          show={isPrintShowing()}
          cancel={() => setProductSheetID(undefined)}
        >
          <LoadProductSheet sheet_id={productSheetID as any as number} />
        </PrintComponent>
      )}
    </>
  );
};

export default ProductLog;
