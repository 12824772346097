import { api } from "../../ApiClient";

export const fetchProductSheets = async (params) => {
  return await api.get(`/productsheets`, params);
};

export const createProductSheet = async (sheet) => {
  return await api.post("/productsheets", sheet);
};

export const fetchProductSheetProducts = async (sheetID) => {
  return await api.get(`/productsheets/${sheetID}/products`);
};

export const fetchProductSheet = async (sheetID) => {
  return await api.get(`/productsheets/${sheetID}`);
};

export const deleteProductSheet = async (sheetID) => {
  return await api.delete(`/productsheets/${sheetID}`);
};

export const deleteProductSheetProduct = async (sheetID, productID) => {
  return await api.delete(`/productsheets/${sheetID}/products/${productID}`);
};

export const updateProductSheetIsFinal = async (sheetID, isFinal) => {
  return await api.patch(`/productsheets/${sheetID}`, { is_final: isFinal });
};
