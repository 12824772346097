import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import LoginForm from "./auth/LoginForm";
import { useUser } from "../contexts/UserProvider";

const Login = () => {
  const { login } = useUser();
  const navigate = useNavigate();

  const handleLogingError = (_err: any, variables: any) => {
    variables.setSubmitting(false);
    variables.setStatus({
      message: "שגיאת חיבור: בדוק את השדות שהוזנו",
      variant: "danger",
    });
  };

  const handleSuccess = () => {
    let next = "/";
    navigate(next);
  };

  //for login
  const mutation = useMutation({
    mutationFn: (variables) => {
      const email = variables.creds.email;
      const password = variables.creds.password;
      return login(email, password);
    },
    onSuccess: handleSuccess,
    onError: handleLogingError,
  });

  const handleLogin = async (
    { email, password }: { email: string; password: string },
    {
      setSubmitting,
      setStatus,
    }: {
      setSubmitting: (yesno: boolean) => void;
      setStatus: (data: any) => void;
    }
  ) => {
    let creds = { email, password };

    //pass creds and callbacks for error/success handling
    let variables = { creds, setSubmitting, setStatus };
    mutation.mutate(variables);
  };

  return <LoginForm handleSubmit={handleLogin} />;
};

export default Login;
