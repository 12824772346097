import { useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import equalWeights from "./manager/utils";
import RawMaterialCell from "../../part/RawPartCell";
import CheckCircle from "../../icons/CheckCircle";
import ModalContainer from "../../layout/ModalContainer";
import PartSelector from "./PartSelector";
import Calculator from "../../icons/Calculator";

export type PartType = {
  id: number;
  raw_part_id: number;
  name: string;
  weight: number;
};

export type Props = {
  id: number;
  raw_weight: number;
  parts_weight: number;
  strict_weight: boolean;
  slaughter_id: string;
  onAddPart: (part: PartType) => void;
};

const RawPartPanelHeader = ({
  id,
  raw_weight,
  parts_weight,
  strict_weight,
  slaughter_id,
  onAddPart,
}: Props) => {
  const isDone = strict_weight && equalWeights(parts_weight, raw_weight);
  const maxWeight = strict_weight ? raw_weight - parts_weight : undefined;
  const [showDialog, setShowDialog] = useState(false);
  const handleNewPart = (
    values: PartType,
    count: number,
    { setSubmitting }: { setSubmitting: (v: boolean) => void }
  ) => {
    for (let i = 0; i < count; i++) {
      onAddPart(values);
    }
    setSubmitting(false);
    setShowDialog(false);
  };

  return (
    <Container
      className={[
        "d-flex justify-content-between align-items-center",
        "py-1",
        "Butcher-RawPartPanelHeader",
      ].join(" ")}
    >
      <div>
        {isDone && (
          <span style={{ color: "#00" }}>
            <CheckCircle />{" "}
          </span>
        )}
        {strict_weight && (
          <span style={{ color: "#00" }}>
            <Calculator />{" "}
          </span>
        )}
        <span className="fw-bold" style={{ fontSize: "1.15rem" }}>
          <RawMaterialCell id={id} />
          {`  (${slaughter_id})`}
        </span>
        <div className="mx-3" style={{ display: "inline-block" }}>
          משקל גולמי: <span className="fw-bold">{raw_weight.toFixed(2)} </span>
        </div>
        משקל חלקים: <span className="fw-bold">{parts_weight.toFixed(2)} </span>{" "}
      </div>
      <div>
        <Button
          variant="primary"
          disabled={isDone}
          onClick={() => setShowDialog(true)}
        >
          הוסף חלק
        </Button>
        <ModalContainer show={showDialog} title="הוסף חלק">
          <PartSelector
            rawId={id}
            handleSubmit={handleNewPart}
            handleCancel={() => setShowDialog(false)}
            maxWeight={maxWeight}
          />
        </ModalContainer>
      </div>
    </Container>
  );
};

export default RawPartPanelHeader;
