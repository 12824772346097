import { useEffect, useRef } from "react";
import { default as BootForm } from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomerSelect from "./CustomerSelect";
import RawPartSelect from "./RawPartSelect";
import SupplierSelect from "./SupplierSelect";
import Status from "../../form/Status";
import SubmitCancel from "../../form/SubmitCancel";
import { validDate } from "./utils";

const CreateForm = ({ handleSubmit, handleCancel }) => {
  const slaughterNoField = useRef();

  useEffect(() => {
    slaughterNoField.current.focus();
  }, []);

  let twoDaysAgo = new Date();
  twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

  return (
    <Formik
      initialValues={{
        slaughtered_at: twoDaysAgo.toLocaleDateString("he-IL"),
        slaughter_number: "",
        supplier: "",
        kind: "",
        part: "",
        temperature: "",
        weight: "",
        comment: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={partSchema}
      validateOnBlur={false}
    >
      {({ isSubmitting, status }) => (
        <Form as={BootForm}>
          {status && (
            <Status message={status.message} variant={status.variant} />
          )}

          <Row className="mb-3">
            <Col>
              <BootForm.Label htmlFor="slaughtered_at">
                יום שחיטה
              </BootForm.Label>
            </Col>

            <Col>
              <Field
                name="slaughtered_at"
                className="form-control"
                type="text"
              />
              <ErrorMessage
                name="slaughtered_at"
                className="text-danger"
                component="div"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <BootForm.Label htmlFor="slaughter_number">
                מס' שחיטה
              </BootForm.Label>
            </Col>
            <Col>
              <Field
                name="slaughter_number"
                innerRef={slaughterNoField}
                className="form-control"
                type="text"
              />
              <ErrorMessage
                name="slaughter_number"
                className="text-danger"
                component="div"
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <BootForm.Label htmlFor="supplier">ספק</BootForm.Label>
            </Col>
            <Col>
              <SupplierSelect name="supplier" />
              <ErrorMessage
                name="supplier"
                className="text-danger"
                component="div"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <BootForm.Label htmlFor="part">חלק גולמי</BootForm.Label>
            </Col>
            <Col>
              <RawPartSelect name="part" />
              <ErrorMessage
                name="part"
                className="text-danger"
                component="div"
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <BootForm.Label htmlFor="weight">משקל</BootForm.Label>
            </Col>
            <Col>
              <Field
                name="weight"
                dir="ltr"
                className="form-control"
                type="text"
              />

              <ErrorMessage
                name="weight"
                className="text-danger"
                component="div"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <BootForm.Label htmlFor="temperature">טמפרטורה</BootForm.Label>
            </Col>
            <Col>
              <Field
                name="temperature"
                dir="ltr"
                className="form-control"
                type="text"
              />
              <ErrorMessage
                name="temperature"
                className="text-danger"
                component="div"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <BootForm.Label htmlFor="kind">שם לקוח</BootForm.Label>
            </Col>
            <Col>
              <CustomerSelect name="kind" />
              <ErrorMessage
                name="kind"
                className="text-danger"
                component="div"
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <BootForm.Label htmlFor="comment">הערה</BootForm.Label>
            </Col>
            <Col>
              <Field name="comment" className="form-control" as="textarea" />
              <ErrorMessage
                name="comment"
                className="text-danger"
                component="div"
              />
            </Col>
          </Row>

          <BootForm.Group>
            <BootForm.Label></BootForm.Label>
            <SubmitCancel
              handleCancel={handleCancel}
              isSubmitting={isSubmitting}
            />
          </BootForm.Group>
        </Form>
      )}
    </Formik>
  );
};

const partSchema = () => {
  return Yup.object({
    slaughtered_at: Yup.string()
      .test("is-date", "תאריך שגוי", (value) => {
        return validDate(value);
      })
      .required("*"),
    slaughter_number: Yup.string().digitsonly().trim().max(64).required("*"),
    supplier: Yup.string().required("*"),
    kind: Yup.string().required("*"),
    part: Yup.string().required("*"),
    temperature: Yup.number().temperature().max(8).min(-50).required("*"),
    weight: Yup.number().positive().weight().required("*"),
  });
};
export default CreateForm;
