import Alert from "react-bootstrap/Alert";

// Variant any of the variants of bootstrap
const Status = ({ message, variant = "info" }) => {
  const found = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ].find((v) => v === variant);

  if (!found) {
    variant = "info";
  }

  return (
    <Alert key={variant} variant={variant}>
      {message}
    </Alert>
  );
};

export default Status;
