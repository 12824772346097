import { useLocation, Navigate } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";

type Props = {
  roles: [string];
  children: JSX.Element;
};

const PrivateRoute = ({ roles, children }: Props) => {
  const { user, isAdmin } = useUser();
  const location = useLocation();

  //loading the user
  if (user === undefined) {
    return null;
  }

  //user is logged out
  if (user === null) {
    const url = location.pathname + location.search + location.hash;
    return <Navigate to="/login" state={{ next: url }} />;
  }

  const isAllowed = (userRole: string) => {
    if (isAdmin()) {
      return true;
    }
    return roles.find((r) => {
      return r === userRole || r === "*";
    });
  };

  //no permission for the route
  if (!isAllowed(user.role)) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default PrivateRoute;
