import { api } from "../../ApiClient";
import { PaginateParams } from "../pagination/Paginator";

interface FetchParams extends PaginateParams {
  processing?: string;
}

export const fetchBatches = async (params: FetchParams) => {
  return await api.get("/batches", params);
};

export const createBatch = async (data: any) => {
  return await api.post("/batches", data);
};

export const deleteBatch = async (id: number | string) => {
  return await api.delete(`/batches/${id}`);
};

export const fetchRawMaterialsForBatch = async (id: number) => {
  return await api.get(`/batches/${id}/rawmaterials`);
};

export const fetchProcessingForBatch = async (id: number) => {
  return await api.get(`/batches/${id}/processing`);
};

export const upsertProcessingForBatch = async (id: number, data: string) => {
  return await api.put(`/batches/${id}/processing`, { data: data });
};
